import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IResume } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getResumesById } from '../../graphql/queries';
import { IReducers } from '../../store';
import Loader from '../../components/Loader';

import ejs from '../../utils/ejs';
import { getFormatedDate, getFormatedDateTime } from '../../utils';
import pt from '../../assets/img/flags/mz.svg';

import en from '../../assets/img/flags/um.svg';

const QUERY = getResumesById;
const DEFAULT_TEMPLATE = 'professional';

const Dashboard: React.FC = () => {
    const [getResumes, { data, loading, error }] = useLazyQuery(QUERY);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();

    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    const RESUME_PHOTO_URL = useSelector(
        (state: IReducers) => state.utilReducer.resumePhotoUrl
    );

    const user = useSelector((state: IReducers) => state.authReducer.user);
    const TEMPLATES = useSelector(
        (state: IReducers) => state.utilReducer.templates
    );
    const viewResume = (resume: IResume) => {
        dispatch({ type: 'SET_RESUME', resume });
        dispatch({
            type: 'SET_ABOUT_PHOTO',
            aboutPhoto: `${RESUME_PHOTO_URL}${resume.id}.jpg?${Math.random() * 1000000
                }`,
        });

        navigate('/resumes/review');
    };
    const logout = () => {
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });
    };


    const getResumeIframe = (newResume: IResume) => {
        let photoUrl = `${API_URL}/resume/photos/${newResume.id}.jpg`;
        let template = DEFAULT_TEMPLATE;
        const templateNames = Object.keys(TEMPLATES);
        for (let i = 0; i < templateNames.length; i++) {
            if (templateNames[i] === newResume.layout) {
                template = templateNames[i];
            }
        }
        const html = ejs.render(TEMPLATES[template], {
            resume: newResume,
            previewCondition: true,
            photoUrl: '',
            // photoUrl
        });
        const url = URL.createObjectURL(
            new Blob([html], { type: 'text/html' })
        );

        return url;
    };

    const getResumeType = (resume: IResume) => {
        if (resume.resumeType === 'new') {
            return 'resume-new';
        }
        if (!resume.validUntil) {
            return '';
        }
        const validUntil = new Date(
            parseInt(resume.validUntil.toString())
        ).getTime();

        if (validUntil < Date.now()) {
            return 'resume-expired';
        }

        return 'resume-valid';
    };

    const getResumeExpireDate = (resume: IResume) => {
        if (resume.resumeType === 'new') {
            return <span style={{ color: '#339dff' }}>{'CV Novo'}</span>;
        }

        if (!resume.validUntil) {
            return 'CV novo (Não pago)';
        }

        const validUntil = new Date(
            parseInt(resume.validUntil.toString())
        ).getTime();

        if (validUntil < Date.now()) {
            return (
                <span style={{ color: '##8f8e8e' }}>
                    {'Expirou aos ' +
                        getFormatedDate(
                            new Date(parseInt(resume.validUntil.toString()))
                        )}
                </span>
            );
        }

        return (<>
            <div style={{ color: '#46c829' }}>
                {'V: ' +
                    getFormatedDateTime(
                        new Date(parseInt(resume.validUntil.toString()))
                    )}
            </div>
        </>);
    };

    const getResumeLanguageFlag = (resume: IResume) => {
        switch (resume.resumeLanguage) {
            case 'pt':
                return pt;
            case 'en':
                return en;
            default:
                return pt;
        }
    };

    useEffect(() => {
        getResumes({ variables: { id: userId } });
    }, []);
    useEffect(() => { }, [data]);

    return (
        <>
            {loading && <Loader></Loader>}
            <div className='dashboard'>
                <div className='logout'>
                    <i className='fa fa-user'></i>

                    <div onClick={logout} className='user-menu'>
                        <div>Sair</div>
                    </div>
                </div>
                <div
                    className='logo'
                    style={{
                        position: 'fixed',
                        zIndex: 3,
                        left: 20,
                        top: 20,
                        fontSize: '20px',
                    }}
                >
                    <span style={{ color: '#5e4f9f' }}>cv</span>.
                    <span style={{ color: '#2488c9' }}>co</span>.
                    <span style={{ color: '#2fb4ca' }}>mz</span>
                </div>
                <div className="vagas">
                    <a href="https://chat.whatsapp.com/L20aufJ8IovC0j1sUh1vqO" target={"_blank"}>
                        <button className='button button-success'>vagas</button>
                    </a>
                </div>

                <div className='body'>
                    <h2>ADMIN:::: Lista dos teus CV's</h2>
                    <div className='resume-list'>
                        <div
                            className='new-resume'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/users');
                            }}
                        >

                            <div className='button button-primary'>Voltar CV</div>
                        </div>
                        {data &&
                            data.resumesByUserId.map((resume: IResume) => {
                                return (
                                    <div key={resume.id} className='item'>
                                        {getResumeType(resume) ===
                                            'resume-valid' && (
                                                <i className='payment-checked fa fa-circle-check'></i>
                                            )}

                                        <div
                                            className='screenshoot'
                                            // style={{
                                            //     backgroundImage:
                                            //         getBackgroundUrl(
                                            //             resume.layout
                                            //         ),
                                            // }}
                                            onClick={() => {
                                                viewResume(resume);
                                            }}
                                        >
                                            <iframe
                                                className='resume-preivew'
                                                title='CV'
                                                src={getResumeIframe(resume)}
                                                width='800'
                                                height='1122'
                                            ></iframe>
                                            <div className='view-back'>
                                                <div className='view'>
                                                    <i className='fa fa-eye'></i>{' '}
                                                    VER
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tools'>
                                            <div className='desc'>
                                                <div className='name'>
                                                    {resume.firstName +
                                                        ' ' +
                                                        resume.lastName}
                                                </div>
                                                <div>
                                                    {/* <div>
                                                        {'ID: ' +
                                                            resume.userId.toString()}
                                                    </div> */}

                                                    <div>
                                                        {'C: ' +
                                                            getFormatedDateTime(
                                                                new Date(parseInt(resume.createdAt.toString()))
                                                            )}
                                                    </div>

                                                    <div >
                                                        {'U: ' +
                                                            getFormatedDateTime(
                                                                new Date(parseInt(resume.updatedAt.toString()))
                                                            )}
                                                    </div>
                                                </div>
                                                <div className='date'>
                                                    {getResumeExpireDate(
                                                        resume
                                                    )}

                                                    <span>
                                                        <img
                                                            src={getResumeLanguageFlag(
                                                                resume
                                                            )}
                                                            style={{
                                                                width: '16px',
                                                            }}
                                                            alt=''
                                                        />
                                                    </span>
                                                </div>
                                                <div
                                                    className={`resume-type ${getResumeType(
                                                        resume
                                                    )}`}
                                                ></div>
                                            </div>

                                            <div className='download'>
                                                <i className='fa fa-download'></i>{' '}
                                                <span>Download</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div
                    style={{
                        // backgroundColor: 'yellow',
                        padding: '10px',
                        marginTop: '20px',
                        color: 'orange',
                    }}
                >
                    Qualquer dúvida clique
                    <a
                        style={{ color: 'blue' }}
                        href='https://wa.me/message/2SGBH7QH2YVUO1'
                        target='_blank'
                        rel='noreferrer'
                    >
                        {' '}
                        aqui{' '}
                    </a>
                    ou entre em contacto com a nossa equipa pelo whatsapp:{' '}
                    <span style={{ color: '#006' }}>+258 84 614 5740</span>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
