import React from 'react';
import {
    convertToRaw,
    EditorState,
    convertFromHTML,
    ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
interface Props {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    placeholder: string;
    value: string;
}

const TextEditor: React.FC<Props> = ({ placeholder, setValue, value }) => {
    const blocksFromHTML = convertFromHTML(value);
    const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    const [editorState, setEditorState] = React.useState(
        EditorState.createWithContent(content)
    );

    const editor = React.useRef(null);

    function focusEditor() {
        // if (editor.current) editor.current.focus();
    }
    const setContent = () => {
        setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <div onClick={focusEditor} className='wrapper-editor'>
            <Editor
                ref={editor}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                placeholder={placeholder}
                stripPastedStyles={true}
                onBlur={(newContent) => {
                    setContent();
                }}
                toolbar={{
                    options: ['list'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                }}
            />
        </div>
    );
};

export default TextEditor;
