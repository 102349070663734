/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';

const create: React.FC = () => {
    const newResume = useSelector(
        (state: IReducers) => state.newResumesReducer
    );
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);

    const RESUME_ID = useSelector(
        (state: IReducers) => state.newResumesReducer.id
    );

    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );

    const RESUME_PHOTO = useSelector(
        (state: IReducers) => state.newResumesReducer.aboutPhoto
    );

    const [about, setAbout] = useState<string>(newResume.aboutMe || '');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const done = () => {
        dispatch({ type: 'SET_RESUME_ABOUT', aboutMe: about });
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.aboutMe, position: 5 },
        });
    }, []);

    return (
        <>
            <div className='container about'>
                <div className='form-group'>
                    <div className='photo'>
                        <img src={RESUME_PHOTO} alt='' />
                    </div>
                    <button
                        className='button button-primary'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/photo');
                        }}
                    >
                        {TEXT.photo}
                    </button>
                </div>
                <div className='form-group'>
                    <label htmlFor=''>{TEXT.aboutLabel}</label>
                    <textarea
                        placeholder={TEXT.profilePlaceholder}
                        rows={4}
                        value={about}
                        onChange={(e) => {
                            setAbout(e.target.value);
                            done();
                        }}
                    />
                </div>

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            done();
                            navigate('/resumes/personal-info');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            done();

                            navigate('/resumes/skill');
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default create;
