import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddReminder from '../../../components/AddReminder';
import { IReducers } from '../../../store';
import { ILanguage } from '../../../types';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const LANGUAGE_LEVELS_PT = [
    'Iniciante',
    'Intermediário',
    'Avançado',
    'Fluente',
    'Nativo',
];

const LANGUAGE_LEVELS_EN = [
    'Beginner',
    'Intermediary',
    'Advanced',
    'Fluent',
    'Native',
];
const Language: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [languageDesc, setLanguageDesc] = useState<string>('');
    const [languageDescError, setLanguageDescError] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedLanguage, setEditedLanguage] = useState<ILanguage>();

    const [languageLevel, setLanguageLevel] = useState<string>('2');

    const resumeLanguage = useSelector(
        (state: IReducers) => state.newResumesReducer.resumeLanguage
    );

    const [languageLevelDesc, setLanguageLevelDesc] = useState<string[]>(
        resumeLanguage === 'en' ? LANGUAGE_LEVELS_EN : LANGUAGE_LEVELS_PT
    );

    const languages = useSelector(
        (state: IReducers) => state.newResumesReducer.languages
    );

    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);
    const [showAddNewButton, setShowAddNewButton] = useState(
        languages.length > 0
    );
    const changeLanguageLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLanguageLevel(e.target.value);
    };

    const addLanguage = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isEditing) {
            if (languageDesc.trim().length > 1) {
                setShowAddNewButton(true);
                dispatch({
                    type: 'EDIT_LANGUAGE',
                    language: {
                        language: languageDesc,
                        level: parseInt(languageLevel),
                        id: editedLanguage?.id,
                    } as ILanguage,
                });

                setLanguageLevel('2');
                setLanguageDesc('');
                setLanguageDescError('');
                setIsEditing(false);
            } else {
                setLanguageDescError('Deve ter no mínimo 2 caracteres');
            }
        } else {
            if (languageDesc.trim().length > 1) {
                setShowAddNewButton(true);
                dispatch({
                    type: 'ADD_LANGUAGE',
                    language: {
                        language: languageDesc,
                        level: parseInt(languageLevel),
                        id: uuidv4(),
                    } as ILanguage,
                });
                setLanguageLevel('2');
                setLanguageDesc('');
                setLanguageDescError('');
            } else {
                setLanguageDescError('Deve ter no mínimo 2 caracteres');
            }
        }
    };
    const editElement = (language: ILanguage) => {
        setEditedLanguage(language);
        setLanguageDesc(language.language);
        setLanguageLevel(language.level + '');
        setIsEditing(true);
        setShowAddNewButton(false);
    };
    const deleteLanguage = (language: ILanguage) => {
        dispatch({ type: 'DELETE_LANGUAGE', language });
    };

    /*********Drag and drop********** */
    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;

        const items = Array.from(languages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: 'SET_LANGUAGES',
            languages: items,
        });
    }

    useEffect(() => {
        // if (new)
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.language, position: 3 },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className='container'>
                <form action=''>
                    {isEditing && <h2 className='editing'>{TEXT.editing}</h2>}
                    {!isEditing &&
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <div className='skill-list'
                                        {...provided.droppableProps} ref={provided.innerRef}
                                    >
                                        {
                                            languages.map((language, index) => {
                                                return (
                                                    <Draggable key={language.id} draggableId={language.id as string} index={index}>
                                                        {(provided) => (

                                                            <div className='skill'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}

                                                            >
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='desc'>
                                                                            {language.language}
                                                                        </div>
                                                                        <div className='level'>
                                                                            {
                                                                                LANGUAGE_LEVELS_PT[
                                                                                language.level
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='col col2'>
                                                                        <span
                                                                            className='action edit'
                                                                            onClick={() => {
                                                                                editElement(language);
                                                                            }}
                                                                        >
                                                                            {TEXT.edit}
                                                                        </span>
                                                                        <span
                                                                            className='action delete'
                                                                            onClick={() => {
                                                                                deleteLanguage(
                                                                                    language
                                                                                );
                                                                            }}
                                                                        >
                                                                            {TEXT.delete}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        {provided.placeholder}

                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                    {showAddNewButton ? (
                        <div className='form-group'>
                            <button
                                className='button button-primary add-new'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddNewButton(false);
                                }}
                            >
                                {TEXT.addNew}{' '}
                                <i className='fa fa-circle-plus'></i>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.language}</label>
                                <input
                                    type='text'
                                    placeholder='Ex: Francês'
                                    value={languageDesc}
                                    onChange={(e) => {
                                        setLanguageDesc(e.target.value);
                                    }}
                                />
                                <span className='error'>
                                    {languageDescError}
                                </span>
                            </div>

                            <div className='form-group'>
                                <label
                                    style={{
                                        position: 'relative',
                                        width: 'auto',
                                        top: '0',
                                        left: '0',
                                    }}
                                    htmlFor=''
                                >
                                    {TEXT.languageLevel}
                                </label>
                                {languageLevelDesc
                                    .map((value: string, index: number) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    type='radio'
                                                    name='language'
                                                    value={index.toString()}
                                                    id={index.toString()}
                                                    onChange={
                                                        changeLanguageLevel
                                                    }
                                                    checked={
                                                        languageLevel ===
                                                        index.toString()
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        position: 'relative',
                                                        width: 'auto',
                                                        top: '0',
                                                        left: '0',
                                                    }}
                                                    htmlFor={index.toString()}
                                                >
                                                    {value}
                                                </label>
                                            </div>
                                        );
                                    })
                                    .reverse()}
                            </div>
                            <div className='form-group'>
                                <button
                                    className='button button-success'
                                    onClick={addLanguage}
                                >
                                    {TEXT.save} <i className='fa fa-save'></i>
                                </button>
                            </div>
                        </>
                    )}
                </form>
                <AddReminder></AddReminder>

                <div className='next-step'>
                    <i
                        className='icon fa fa-circle-left'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/experience');
                        }}
                    >
                        {' '}
                    </i>
                    <i
                        className='icon fa fa-circle-right'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/personal-info');
                        }}
                    >
                        {' '}
                    </i>
                </div>

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/experience');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/personal-info');
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Language;
