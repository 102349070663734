import React, { useEffect, useState } from 'react';
import modernResume from '../../../assets/img/templates/modern-resume.png';
import firstTemplate from '../../../assets/img/templates/first-template.png';
import professional from '../../../assets/img/templates/professional.png';
import gradient from '../../../assets/img/templates/gradient.png';
import white from '../../../assets/img/templates/white.png';
import skyBlue from '../../../assets/img/templates/sky-blue.png';
import gray from '../../../assets/img/templates/gray.png';
import simple from '../../../assets/img/templates/simple.png';
import redBlue from '../../../assets/img/templates/red-blue.png';
import newWhite from '../../../assets/img/templates/new-white.png';
import newProfessional from '../../../assets/img/templates/new-professional.png';

import { updateResumeMutation } from '../../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { getAtualResume } from '../../../utils';
import { IResume } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../store';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';

const QUERY = updateResumeMutation;
const TEMPLATES = [
    'red-blue',
    'new-white',
    'simple',
    'sky-blue',
    'new-professional',
    'white',
    'professional',
    'gray',
    'first-template',
    'gradient',
    'modern-resume',
];
const NewResumeTemplate: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [layout, setLayout] = useState('standard');
    const [updateResume, { data, loading, error, reset }] = useMutation(QUERY);
    const newResume = useSelector(
        (state: IReducers) => state.newResumesReducer
    );

    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);

    const saveAndPreview = async (e: React.MouseEvent) => {
        dispatch({ type: 'SET_TEMPLATE', layout });
        const resumeData: IResume = getAtualResume(newResume);
        await updateResume({
            variables: {
                id: newResume.id,
                data: { ...resumeData, layout: layout },
            },
        });
        navigate('/resumes/education');
    };

    const viewTemplate = (template: string) => {
        setLayout(template);

        switch (template) {
            case 'new-professional':
                setSelectedTemplate(newProfessional);
                break;
            case 'red-blue':
                setSelectedTemplate(redBlue);
                break;
            case 'new-white':
                setSelectedTemplate(newWhite);
                break;
            case 'simple':
                setSelectedTemplate(simple);
                break;
            case 'gray':
                setSelectedTemplate(gray);
                break;
            case 'modern-resume':
                setSelectedTemplate(modernResume);
                break;
            case 'first-template':
                setSelectedTemplate(firstTemplate);
                break;
            case 'professional':
                setSelectedTemplate(professional);
                break;
            case 'gradient':
                setSelectedTemplate(gradient);
                break;
            case 'white':
                setSelectedTemplate(white);
                break;
            case 'sky-blue':
                setSelectedTemplate(skyBlue);
                break;
            default:
                break;
        }
    };

    const next = () => {
        let index = TEMPLATES.indexOf(layout) + 1;

        if (index === TEMPLATES.length) {
            index = 0;
        } else {
        }

        viewTemplate(TEMPLATES[index]);
    };
    const previous = () => {
        let index = TEMPLATES.indexOf(layout) - 1;

        if (index === -1) {
            index = TEMPLATES.length - 1;
        } else {
        }

        viewTemplate(TEMPLATES[index]);
    };

    dispatch({
        type: 'SET_RESUME_HEADER',
        resumesHeader: { desc: TEXT.menu.template, position: 0 },
    });
    useEffect(() => {
        setSelectedTemplate(professional);
    }, []);

    return (
        <>
            {loading && <Loader></Loader>}

            {selectedTemplate && (
                <div className='view-template'>
                    <div className='template-navigation'>
                        <div className='previous' onClick={previous}>
                            <i className='fa fa-arrow-left'></i>
                        </div>
                        <div className='next' onClick={next}>
                            <i className='fa fa-arrow-right'></i>
                        </div>
                    </div>
                    <img src={selectedTemplate} alt='' />
                    <div className='buttons'>
                        <button
                            className='button-success button'
                            onClick={saveAndPreview}
                        >
                            {TEXT.choose}
                        </button>
                        <button
                            className='button-primary button'
                            onClick={() => {
                                setSelectedTemplate('');
                            }}
                        >
                            {TEXT.viewAllTemplates}
                        </button>
                    </div>
                </div>
            )}

            <div className='templates-container'>
                <div
                    className='over-close'
                    onClick={() => {
                        navigate('/resumes/review');
                    }}
                ></div>
                <div className='templates'>
                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('red-blue');
                        }}
                    >
                        <img src={redBlue} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('new-white');
                        }}
                    >
                        <img src={newWhite} alt='' />
                    </div>
                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('simple');
                        }}
                    >
                        <img src={simple} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('sky-blue');
                        }}
                    >
                        <img src={skyBlue} alt='' />
                    </div>
                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('new-professional');
                        }}
                    >
                        <img src={newProfessional} alt='' />
                    </div>
                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('white');
                        }}
                    >
                        <img src={white} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('professional');
                        }}
                    >
                        <img src={professional} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('gray');
                        }}
                    >
                        <img src={gray} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('first-template');
                        }}
                    >
                        <img src={firstTemplate} alt='' />
                    </div>
                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('gradient');
                        }}
                    >
                        <img src={gradient} alt='' />
                    </div>

                    <div
                        className='template'
                        onClick={() => {
                            viewTemplate('modern-resume');
                        }}
                    >
                        <img src={modernResume} alt='' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewResumeTemplate;
