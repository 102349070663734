import React from 'react';
import { useSelector } from 'react-redux';
import { IReducers } from '../store';
import { gql, useLazyQuery } from '@apollo/client';

// import { Container } from './styles';

const QUERY = gql`
    query User($id: ID) {
        user(id: $id) {
            email
            firstName
            lastName
            id
        }
    }
`;
const About: React.FC = () => {
    const { user } = useSelector((state: IReducers) => state.authReducer);
    const [getUser, { loading, error, data }] = useLazyQuery(QUERY);

    return (
        <>
            <h1
                onClick={() => {
                    getUser({ variables: { id: '624ff94e607de7ad5cf9868e' } });
                }}
            >
                {user?.firstName}
            </h1>
            <h2>{data?.user.firstName}</h2>
            <h2>{error?.message}</h2>
        </>
    );
};

export default About;
