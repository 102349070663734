import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recommendation } from '../graphql/mutations';
import { IReducers } from '../store';

const QUERY = recommendation;

const Welcome: React.FC = () => {
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    const user = useSelector((state: IReducers) => state.authReducer.user);
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [recommendation, setRecommendation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // console.log(user?.email);
    const [addRecommendation, { data, loading, error }] = useMutation(QUERY);

    const gotToTestPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (recommendation.trim().length > 0) {
            addRecommendation({
                variables: {
                    id: recommendation.trim(),
                },
            });
        }
    };
    const ignoreAndGotToTestPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        addRecommendation({
            variables: {
                id: '0',
            },
        });
        navigate('/');
    };

    useEffect(() => {
        if (data) {
            console.log(data);
            if (data.recommendation) {
                navigate('/');
            } else {
                setErrorMessage('Código inváldio ou expirado');
            }
        }
        if (error) {
            setErrorMessage('Erro de conecção');
        }
    }, [data, error]);

    return (
        <>
            <div className='container'>
                <form action=''>
                    <div className='form-group'>
                        <label htmlFor=''>
                            Bem vindo
                            <br />{' '}
                            <b>
                                {' '}
                                {user?.firstName} {user?.lastName}
                            </b>
                        </label>

                        <i
                            className='fa fa-check-circle'
                            style={{ fontSize: '120px', color: '#0e0' }}
                        ></i>
                    </div>

                    <br />
                    {step === 0 ? (
                        <div className='tutorial'>
                            <div className='form-group'>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: 'orange',
                                    }}
                                >
                                    {/* <i className='fa fa-exclamation-triangle'></i>{' '} */}
                                    ID da utilizador que recomendou a aplicação{' '}
                                    <br /> (Não obrigatório)
                                </h2>
                                <form action=''>
                                    <div className='form-group'>
                                        <label htmlFor=''></label>
                                        <input
                                            type='text'
                                            placeholder='Não obrigatório'
                                            value={recommendation}
                                            onChange={(e) => {
                                                setRecommendation(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <span className='error'>
                                            {errorMessage}
                                        </span>
                                    </div>
                                </form>
                                {/* <p>
                                    <br />A seguir encontrará um cv já
                                    preenchido, tente editar este CV para ver
                                    como funciona.
                                </p> */}
                            </div>
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <button
                                    className='button button-success'
                                    onClick={(e) => {
                                        // setStep(1);
                                        gotToTestPage(e);
                                    }}
                                >
                                    Validar
                                </button>

                                <button
                                    className='button button-primary'
                                    onClick={(e) => {
                                        // setRecommendation('0')
                                        ignoreAndGotToTestPage(e);
                                    }}
                                >
                                    Ignorar e continuar
                                </button>
                            </div>

                            <div
                                style={{
                                    // backgroundColor: 'yellow',
                                    padding: '10px',
                                    marginTop: '20px',
                                    color: 'orange',
                                }}
                            >
                                Qualquer dúvida entre em contacto com a nossa
                                equipa pelo Whatsapp:{' '}
                                <div style={{ color: 'blue' }}>
                                    +258 86 435 2310
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='tutorial'>
                            <div className='form-group'>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: '#3574f3',
                                    }}
                                >
                                    <i className='fa fa-info-circle'></i> A
                                    seguir encontrará a um CV com o teu nome,
                                    edite o mesmo gere e faça download por 2h
                                </h2>
                                <br />
                                <p
                                    style={{
                                        fontSize: '20px',
                                        color: '#3574f3',
                                    }}
                                >
                                    Pode criar um novo CV e previsualizar a
                                    qualquer momento, mas os novos CV's serão
                                    cobrados 49MT apenas na geração do PDF e
                                    poderá gerar e editar o mesmo durante 7 dias
                                    a qualquer momeno, excepto o nome
                                </p>
                            </div>
                            <br />
                            <br />
                            <button
                                className='button button-primary'
                                onClick={gotToTestPage}
                            >
                                Começar
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default Welcome;
