import { IUser } from '../types';

export interface IAction {
    type: string;
    user: IUser;
}
export interface IAuthState {
    user: IUser | null;
}

const INITIAL_STATE: IAuthState = {
    user: null,
};
function authReducer(state = INITIAL_STATE, action: IAction): IAuthState {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.user };

        case 'LOGOUT':
            return { ...state, user: null };

        default:
            return state;
    }
}

export default authReducer;
