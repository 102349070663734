import React from 'react';

// import { Container } from './styles';

const Loader: React.FC = () => {
    return (
        <>
            <div className='loader-conteiner'>
                <span className='loader'></span>
            </div>
        </>
    );
};

export default Loader;
