import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserResumes } from '../graphql/queries';
import { IReducers } from '../store';

const NewPasswordSuccess: React.FC = () => {
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    const user = useSelector((state: IReducers) => state.authReducer.user);
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    // console.log(user?.email);

    const gotToTestPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        navigate('/');
    };

    return (
        <>
            <div className='container'>
                <form action=''>
                    <div className='form-group'>
                        <label htmlFor=''>
                            Bem vindo
                            <br />{' '}
                            <b>
                                {' '}
                                {user?.firstName} {user?.lastName}
                            </b>
                        </label>

                        <i
                            className='fa fa-check-circle'
                            style={{ fontSize: '120px', color: '#0e0' }}
                        ></i>
                    </div>

                    <br />
                    {step === 0 ? (
                        <div className='tutorial'>
                            <div className='form-group'>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: 'green',
                                    }}
                                >
                                    <i className='fa fa-info'></i> Recuperou a
                                    password com successo
                                </h2>
                            </div>
                            <div>
                                <br />
                                <button
                                    className='button button-primary'
                                    onClick={(e) => {
                                        // setStep(1);
                                        gotToTestPage(e);
                                    }}
                                >
                                    Continuar
                                </button>
                            </div>

                            <div
                                style={{
                                    // backgroundColor: 'yellow',
                                    padding: '10px',
                                    marginTop: '20px',
                                    color: 'orange',
                                }}
                            >
                                Qualquer dúvida ou erro clique
                                <a
                                    style={{ color: 'blue' }}
                                    href='https://wa.me/message/2SGBH7QH2YVUO1'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {' '}
                                    aqui{' '}
                                </a>
                                ou entre em contacto com a nossa equipa pelo
                                Whatsapp:{' '}
                                <div style={{ color: 'blue' }}>
                                    +258 84 614 5740
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='tutorial'>
                            <div className='form-group'>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: '#3574f3',
                                    }}
                                >
                                    <i className='fa fa-info-circle'></i> A
                                    seguir encontrará a um CV com o teu nome,
                                    edite o mesmo gere e faça download por 2h
                                </h2>
                                <br />
                                <p
                                    style={{
                                        fontSize: '20px',
                                        color: '#3574f3',
                                    }}
                                >
                                    Pode criar um novo CV e previsualizar a
                                    qualquer momento, mas os novos CV's serão
                                    cobrados 49MT apenas na geração do PDF e
                                    poderá gerar e editar o mesmo durante 7 dias
                                    a qualquer momeno, excepto o nome
                                </p>
                            </div>
                            <br />
                            <br />
                            <button
                                className='button button-primary'
                                onClick={gotToTestPage}
                            >
                                Começar
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default NewPasswordSuccess;
