/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';

import MPesa from './MPesa';
import Voucher from './Voucher';

const Payment: React.FC = () => {
    const [startPayment, setStartPayment] = useState(false);
    const [plan, setPlan] = useState(1)
    const user = useSelector((state: IReducers) => state.authReducer.user);
    const navigate = useNavigate();


    return (
        <div className='col'>
            <div className="form-group">
                <h2 className='font-color'>Escolha o plano</h2>
                <br />
            </div>

            <div className='row choose-plan'>
                <div className="row">
                    <div className='button button-success-border'
                        onClick={() => {
                            setPlan(1)
                        }}
                    >
                        <h1>
                            49 MT{' '}
                            <span style={{ fontSize: 12, fontWeight: 400 }}>
                                / 30 dias
                            </span>
                        </h1>
                    </div>
                    <div className='button button-primary-border'
                        onClick={() => {
                            setPlan(0)
                        }}
                    >
                        Grátis{' '}
                        <span style={{ fontSize: 12, fontWeight: 400 }}>
                            / 3 dias
                        </span>
                    </div>
                </div>

            </div>
            <br />
            <div className='products-wrapper'>
                <div className='product'
                    style={{
                        borderColor: (plan ? '#46c829' : '#3574f3'),
                    }}
                >
                    <div >
                        {plan === 1 ?
                            <div className='title'>
                                49 MT{' '}
                                <span style={{ fontSize: 16, fontWeight: 400 }}>
                                    / 30 dias
                                </span>
                            </div> :
                            <div className='title'>
                                Grátis{' '}
                                <span style={{ fontSize: 16, fontWeight: 400 }}>
                                    / 3 dias
                                </span>
                            </div>

                        }


                    </div>

                    <button
                        className={+ plan ? "button  button-success" : ' button button-primary'}
                        onClick={() => {
                            // setStartPayment(!startPayment);
                            plan ?
                                navigate('/resumes/payment/mpesa') :
                                navigate('/resumes/payment/free');

                        }}
                    >
                        Pagar
                    </button>
                    {user?.email === 'simoeszunguze@gmail.com' && (
                        <button
                            className='button'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/resumes/payment/admin');
                            }}
                        >
                            <span>Admin PayMent</span>
                        </button>
                    )}
                    <div className='product-body'>
                        <div className='product-detail'>
                            <i className='fa fa-check' />{' '}
                            <p>
                                Depois de pagar pode continuar a editar,
                                mudar modelo e fazer download por {plan ? '30' : '3'} dias
                                sem precisar pagar de novo
                            </p>
                        </div>
                        <div className='product-detail'>
                            <i className='fa fa-check' />{' '}
                            <p>O formato fornecido é o PDF</p>
                        </div>

                        <div className='product-detail'>
                            <i className='fa fa-check' />{' '}
                            <p>
                                Depois de expirar o período de pagamento
                                continurá podendo editar o teu CV
                            </p>
                        </div>
                        <div className='product-detail'>
                            <i className='fa fa-check' />
                            <p>Válido só para este CV depois de pago</p>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Payment;
