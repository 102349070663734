import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';
import { IElement, IResume, ISection } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import AddReminder from '../../../components/AddReminder';
import { getAtualResume } from '../../../utils';
import { updateResumeMutation } from '../../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const QUERY = updateResumeMutation;
const HEADER = 'PERFIL ACADÊMICO';
const Education: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [updateResume, { data, loading, reset }] = useMutation(QUERY);
    const [isEditing, setIsEditing] = useState(false);

    const [education, setEducation] = useState<IElement>({
        id: uuidv4(),
        desc: '',
        name: '',
        start: '',
        end: '',
        details: '',
    });
    const [error, setError] = useState('');

    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );

    const educations = useSelector(
        (state: IReducers) => state.newResumesReducer.educations
    );

    const newResume = useSelector(
        (state: IReducers) => state.newResumesReducer
    );

    const [showAddNewButton, setShowAddNewButton] = useState(
        educations.length > 0
    );

    const addElement = (e: React.MouseEvent) => {
        e.preventDefault();

        if (education.desc.trim().length > 1 && education.name.length > 1) {
            e.preventDefault();
            if (isEditing) {
                dispatch({
                    type: 'EDIT_EDUCATION',
                    education,
                });
            } else {
                dispatch({
                    type: 'ADD_EDUCATION',
                    education,
                });
            }
            setEducation({
                id: uuidv4(),
                desc: '',
                name: '',
                start: '',
                end: '',
                details: '',
            });
            setError('');
            setShowAddNewButton(true);
        } else {
            setError('Os dois campos acima devem ser preenchidos');
        }
        setShowAddNewButton(true);
        setError('');
        setIsEditing(false);
    };

    const editElement = (education: IElement) => {
        setEducation({
            id: education.id,
            desc: education.desc,
            name: education.name,
            start: education.start,
            end: education.end,
            details: '',
        });

        setIsEditing(true);
        setShowAddNewButton(false);
    };
    const deleteElement = (education: IElement) => {
        dispatch({
            type: 'DELETE_EDUCATION',
            education,
        });
    };

    const saveAndReview = async (e: React.MouseEvent) => {
        const resumeData: IResume = getAtualResume(newResume);
        try {
            await updateResume({
                variables: {
                    id: newResume.id,
                    data: resumeData,
                },
            });
            navigate('/resumes/review');
        } catch (error) {
            console.error(error);
        }
    };

    /*********Drag and drop********** */
    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;

        const items = Array.from(educations);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: 'SET_EDUCATIONS',
            educations: items,
        });
    }

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.education, position: 1 },
        });
    }, []);

    return (
        <>
            <div className='container'>
                <form action=''>
                    {isEditing && <h2 className='editing'>{TEXT.editing}</h2>}
                    {!isEditing &&
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <div className='school-list'  {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            educations.map((section, index) => {
                                                return (
                                                    <Draggable key={section.id} draggableId={section.id as string} index={index}>
                                                        {(provided) => (

                                                            <div
                                                                className='school'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='school-name'>
                                                                            {section.desc}
                                                                        </div>
                                                                        <div className='grade'>
                                                                            {section.name}
                                                                        </div>
                                                                        <div className='interval'>
                                                                            <div className='begin'>
                                                                                {section.start}
                                                                            </div>{' '}
                                                                            -
                                                                            <div className='end'>
                                                                                {section.end}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col'>
                                                                        <span
                                                                            className='action edit'
                                                                            onClick={() => {
                                                                                editElement(section);
                                                                            }}
                                                                        >{TEXT.edit}</span>
                                                                        <span
                                                                            className='action delete'
                                                                            onClick={() => {
                                                                                deleteElement(section);
                                                                            }}
                                                                        >{TEXT.delete}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>

                                                );
                                            })}
                                        {provided.placeholder}

                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>}
                    {showAddNewButton ? (
                        <div className='form-group'>
                            <button
                                className='button button-primary add-new'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddNewButton(false);
                                }}
                            >
                                {`${TEXT.addNew} `}
                                <i className='fa fa-circle-plus'></i>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.school}</label>
                                <input
                                    type='text'
                                    placeholder={TEXT.schoolPlaceholder}
                                    value={education.desc}
                                    onChange={(e) => {
                                        setEducation({
                                            ...education,
                                            desc: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.grade}</label>
                                <input
                                    type='text'
                                    placeholder={TEXT.gradePlaceholder}
                                    value={education.name}
                                    onChange={(e) => {
                                        setEducation({
                                            ...education,
                                            name: e.target.value,
                                        });
                                    }}
                                />
                                <span className='error'>{error}</span>
                            </div>
                            <div className='row'>
                                <div className='form-group'>
                                    <label htmlFor=''>
                                        {TEXT.start}
                                    </label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.startPlaceholder}

                                        value={education.start}
                                        onChange={(e) => {
                                            setEducation({
                                                ...education,
                                                start: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor=''>
                                        {TEXT.end}

                                    </label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.endPlaceholder}
                                        value={education.end}
                                        onChange={(e) => {
                                            setEducation({
                                                ...education,
                                                end: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <button
                                    className='button button-success'
                                    onClick={addElement}
                                >
                                    {TEXT.save} <i className='fa fa-save'></i>
                                </button>
                            </div>
                        </>
                    )}
                </form>
                <AddReminder></AddReminder>

                <div className='next-step'>
                    <div className='preview' onClick={saveAndReview}>
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/course');
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Education;
