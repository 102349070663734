import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';
import { IElement } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import AddReminder from '../../../components/AddReminder';
import TextEditor from '../../../components/TextEditor';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const Course: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [details, setDetails] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editor, seteditor] = useState<React.FC>();
    const [course, setCourse] = useState<IElement>({
        id: uuidv4(),
        desc: '',
        name: '',
        start: '',
        end: '',
        details: '',
    });

    const [error, setError] = useState('');

    const courses = useSelector(
        (state: IReducers) => state.newResumesReducer.courses
    );
    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);
    const [showAddNewButton, setShowAddNewButton] = useState(
        courses.length > 0
    );

    const addElement = (e: React.MouseEvent) => {
        e.preventDefault();
        console.log(details);

        if (course.desc.trim().length > 1 && course.name.length > 1) {
            e.preventDefault();
            if (isEditing) {
                dispatch({
                    type: 'EDIT_COURSE',
                    course: { ...course, details: details },
                });
            } else {
                dispatch({
                    type: 'ADD_COURSE',
                    course: { ...course, details: details },
                });
            }

            setCourse({
                id: uuidv4(),
                desc: '',
                name: '',
                start: '',
                end: '',
                details: '',
            });
            setDetails('');
            setShowAddNewButton(true);
            setIsEditing(false);
            setError('');
        } else {
            setError('Os dois campos acima devem ser preenchidos');
        }
    };

    const editElement = (course: IElement) => {
        setCourse({
            id: course.id,
            desc: course.desc,
            name: course.name,
            start: course.start,
            end: course.end,
            details: course.details,
        });
        // console.log(details);
        if (course.details) {
            setDetails(course.details);
        } else {
            setDetails('<p></p>');
        }
        setIsEditing(true);
        setShowAddNewButton(false);
    };
    const deleteElement = (course: IElement) => {
        dispatch({
            type: 'DELETE_COURSE',
            course,
        });
    };

    const getEditor = (details: string) => {
        console.log(details);
        return (
            <TextEditor
                placeholder={TEXT.courseDetailsPlaceholder}
                setValue={setDetails}
                value={details}
            ></TextEditor>
        );
    };


    /*********Drag and drop********** */
    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;

        const items = Array.from(courses);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: 'SET_COURSES',
            courses: items,
        });
    }

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.course, position: 1 },
        });
    }, []);
    return (
        <>
            <div className='container'>
                <form action=''>
                    {isEditing && <h2 className='editing'>{TEXT.editing}</h2>}
                    {!isEditing &&
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <div className='school-list'
                                        {...provided.droppableProps} ref={provided.innerRef}
                                    >
                                        {
                                            courses.map((section, index) => {
                                                return (
                                                    <Draggable key={section.id} draggableId={section.id as string} index={index}>
                                                        {(provided) => (

                                                            <div
                                                                className='school'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='school-name'>
                                                                            {section.desc}
                                                                        </div>
                                                                        <div className='grade'>
                                                                            {section.name}
                                                                        </div>
                                                                        <div className='interval'>
                                                                            <div className='begin'>
                                                                                {section.start}
                                                                            </div>{' '}
                                                                            -
                                                                            <div className='end'>
                                                                                {section.end}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col col2'>
                                                                        <span
                                                                            className='action edit'
                                                                            onClick={() => {
                                                                                editElement(section);
                                                                            }}
                                                                        >
                                                                            {TEXT.edit}
                                                                        </span>
                                                                        <span
                                                                            className='action delete'
                                                                            onClick={() => {
                                                                                deleteElement(section);
                                                                            }}
                                                                        >
                                                                            {TEXT.delete}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>

                                                );
                                            })}
                                        {provided.placeholder}

                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>}
                    {showAddNewButton ? (
                        <div className='form-group'>
                            <button
                                className='button button-primary add-new'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddNewButton(false);
                                }}
                            >
                                {TEXT.addNew}{' '}
                                <i className='fa fa-circle-plus'></i>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.courseLocation}</label>
                                <input
                                    type='text'
                                    placeholder={TEXT.courseLocationPlaceholder}
                                    value={course.desc}
                                    onChange={(e) => {
                                        setCourse({
                                            ...course,
                                            desc: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.courseName}</label>
                                <input
                                    type='text'
                                    placeholder={TEXT.courseNamePlaceholder}
                                    value={course.name}
                                    onChange={(e) => {
                                        setCourse({
                                            ...course,
                                            name: e.target.value,
                                        });
                                    }}
                                />
                                <span className='error'>{error}</span>
                            </div>
                            <div className='row'>
                                <div className='form-group'>
                                    <label htmlFor=''>{TEXT.start}</label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.startPlaceholder}
                                        value={course.start}
                                        onChange={(e) => {
                                            setCourse({
                                                ...course,
                                                start: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor=''>{TEXT.end}</label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.endPlaceholder}
                                        value={course.end}
                                        onChange={(e) => {
                                            setCourse({
                                                ...course,
                                                end: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor=''>{TEXT.courseDetails}</label>
                                {details ? getEditor(details) : getEditor('')}
                            </div>

                            <div className='form-group'>
                                <button
                                    className='button button-success'
                                    onClick={addElement}
                                >
                                    Salvar <i className='fa fa-save'></i>
                                </button>
                            </div>
                        </>
                    )}
                </form>
                <AddReminder></AddReminder>

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/education');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/experience');
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Course;
