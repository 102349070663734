import { combineReducers, createStore } from 'redux';
import authReducer, { IAuthState } from './authReducer';
import { INewResumeState, newResumesReducer } from './newResumeReducer';
import { IResumeState, resumesReducer } from './resumesReducer';
import utilReducer, { IUtilState } from './utilReducer';

export interface IReducers {
    authReducer: IAuthState;
    resumesReducer: IResumeState;
    newResumesReducer: INewResumeState;
    utilReducer: IUtilState;
}
const reducers = combineReducers({
    authReducer,
    resumesReducer,
    newResumesReducer,
    utilReducer,
});

export default createStore(reducers);
