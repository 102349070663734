import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../store';
import jwt_decode from 'jwt-decode';
import { IUser } from '../types';
import Loader from '../components/Loader';
import GoogleLogin from 'react-google-login';
import LoaderWithText from '../components/LoaderWithText';
import { relative } from 'path';

interface IRegisterUser {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    repeatPassword: string;

    email: string;
}

const RecoverPassword: React.FC = () => {
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [termsError, setTermsError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [activationCode, setActivationCode] = useState('');
    const [showCodeForm, setShowCodeForm] = useState(false);
    const [checked, setChecked] = React.useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const [wait, setWait] = useState(false);
    const [error, setError] = useState('');

    const [user, setUser] = useState<IRegisterUser>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        email: '',
        repeatPassword: '',
    });

    const [errorMessages, setErrorMessages] = useState<IRegisterUser>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        email: '',
        repeatPassword: '',
    });

    const handleChangeTermsCheckbox = () => {
        setChecked(!checked);
        // console.log(!checked ? "yes" : "no");
    };

    const register = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (validateRegister()) {
            setLoading(true);

            axios
                .post(`${API_URL}/recover-password`, {
                    phoneNumber: user.phoneNumber.trim(),
                    password: user.password,
                } as IRegisterUser)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code === 100) {
                        setShowCodeForm(true);
                    } else if (response.data.code === 102) {
                        setErrorMessages({
                            ...errorMessages,
                            phoneNumber:
                                'Usurário não registado, volte ao login e tente registar',
                        });
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log('error');

                    console.log(error.response.data);
                    setLoading(false);
                    if (error.response.data.code === 101) {
                        setErrorMessages({
                            ...errorMessages,
                            phoneNumber: 'Erro: Número já foi registado',
                        });
                    }
                });
        }
    };

    const validateRegister = (): boolean => {
        let isValidFrom = true;
        const messages: IRegisterUser = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            password: '',
            email: '',
            repeatPassword: '',
        };

        // Phone number
        if (/^8[2-7][0-9]{7}$/.test(user.phoneNumber)) {
            setErrorMessages({ ...errorMessages, phoneNumber: '' });
        } else {
            isValidFrom = false;
            messages.phoneNumber = 'Número de telefone inválido';
        }

        // Password
        if (user.password.length > 4) {
            if (user.password === user.repeatPassword) {
                setErrorMessages({
                    ...errorMessages,
                    password: '',
                });
            } else {
                isValidFrom = false;
                messages.repeatPassword = 'As password não são iguais';
            }
        } else {
            isValidFrom = false;
            messages.password = 'Passord muito curta';
        }

        if (user.password.length > 4) {
            if (user.password === user.repeatPassword) {
                setErrorMessages({
                    ...errorMessages,
                    password: '',
                });
            } else {
                isValidFrom = false;
                messages.repeatPassword = 'As password não são iguais';
            }
        } else {
            isValidFrom = false;
            messages.password = 'Passord muito curta';
        }

        if (checked) {
            setTermsError('');
        } else {
            setTermsError('Deve concordar com os nosso termos e condições');
            isValidFrom = false;
        }
        setChecked(false);
        setErrorMessages(messages);
        return isValidFrom;
    };

    const activation = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(`${API_URL}/activate-new-password`, {
                ...user,
                activationCode,
            })
            .then((response) => {
                setLoading(false);
                const accessToken = response.data.accessToken;
                let decoded: IUser = jwt_decode(accessToken);
                let user: IUser = { ...decoded, token: accessToken };

                dispatch({
                    type: 'SET_USER',
                    user,
                });
                localStorage.setItem('token', JSON.stringify(user));
                navigate('/');
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                if (error.response.status === 401) {
                    setErrorMessage(
                        'Código de activação inválido. Tente novamente'
                    );
                }
            });
    };

    const responseGoogleSuccess = (response: any) => {
        axios
            .post(`${API_URL}/auth/google`, { tokenId: response.tokenId })
            .then((response) => {
                const accessToken = response.data.accessToken;
                let decoded: IUser = jwt_decode(accessToken);
                let user: IUser = { ...decoded, token: accessToken };
                navigate('/register');

                dispatch({
                    type: 'SET_USER',
                    user,
                });
                localStorage.setItem('token', JSON.stringify(user));
                setWait(false);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    setError('Não autorizado a fazer login com google');
                } else {
                    setError('Erro na conecção');
                }
                setWait(false);

                console.error(error);
            });
    };

    const responseGoogleError = (response: any) => {
        setWait(false);
        console.log(response);
    };
    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Experiencia Profissional', position: 2 },
        });
    }, []);

    return (
        <>
            {loading && <Loader></Loader>}
            {wait && <LoaderWithText text='Autenticando...'></LoaderWithText>}

            <div className='container'>
                <div className='register-header'>
                    <div className='logo'>
                        <span style={{ color: '#5e4f9f' }}>cv</span>.
                        <span style={{ color: '#2488c9' }}>co</span>.
                        <span style={{ color: '#2fb4ca' }}>mz</span>
                    </div>
                    {!showCodeForm && (
                        <div>
                            <div>
                                <div className='form-group'>
                                    <div>
                                        Para voltar ao login clique{' '}
                                        <span
                                            onClick={() => {
                                                navigate('/');
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className='color-primary'
                                        >
                                            aqui
                                        </span>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    )}
                    <h2>Recuperar Password</h2>
                </div>
                {showCodeForm ? (
                    <form action=''>
                        <div className='form-group'>
                            <label htmlFor='' style={{ position: 'relative' }}>
                                Insira o código que foi enviado para o número:{' '}
                                <b> {user.phoneNumber}</b>
                            </label>
                            <input
                                type='number'
                                placeholder='Insira o código aqui:'
                                value={activationCode}
                                onChange={(e) => {
                                    setActivationCode(e.target.value);
                                }}
                            />
                            <span className='error'>{errorMessage}</span>
                        </div>

                        <br />
                        <button
                            className='button button-success'
                            onClick={activation}
                        >
                            Continuar
                        </button>
                    </form>
                ) : (
                    <form action=''>
                        <div className='form-group'>
                            <label htmlFor=''>
                                Número de telefone (+258) *
                            </label>
                            <input
                                type='number'
                                placeholder='Ex: 813323442'
                                value={user.phoneNumber}
                                onChange={(e) => {
                                    setUser({
                                        ...user,
                                        phoneNumber: e.target.value,
                                    });
                                }}
                            />
                            <span className='error'>
                                {errorMessages.phoneNumber}
                            </span>
                        </div>
                        <div className='form-group'>
                            <label htmlFor=''>Nova Passowrd</label>
                            <input
                                type='password'
                                placeholder='Password'
                                value={user.password}
                                onChange={(e) => {
                                    setUser({
                                        ...user,
                                        password: e.target.value,
                                    });
                                }}
                            />
                            <span className='error'>
                                {errorMessages.password}
                            </span>
                        </div>
                        <div className='form-group'>
                            <label htmlFor=''>Repita a Password</label>
                            <input
                                type='password'
                                placeholder='Password'
                                value={user.repeatPassword}
                                onChange={(e) => {
                                    setUser({
                                        ...user,
                                        repeatPassword: e.target.value,
                                    });
                                }}
                            />
                            <span className='error'>
                                {errorMessages.repeatPassword}
                            </span>
                        </div>
                        <br />
                        <div className='form-group'>
                            <span>
                                {' '}
                                <input
                                    type='checkbox'
                                    checked={checked}
                                    onChange={handleChangeTermsCheckbox}
                                />{' '}
                                Concordo com os{' '}
                                <a
                                    target={'_blank'}
                                    href='https://cv.co.mz/terms.html'
                                    rel='noreferrer'
                                >
                                    {' '}
                                    Termos e condições
                                </a>
                            </span>
                            <span className='error'>{termsError}</span>
                        </div>
                        <button
                            className='button button-success'
                            onClick={register}
                        >
                            Continuar
                        </button>
                        <br />
                        <label htmlFor=''>
                            * Registo com número de telefone só é válido em
                            moçambique
                        </label>
                    </form>
                )}
            </div>
        </>
    );
};

export default RecoverPassword;
