/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { IResume, ISection, ISkill, IUser } from '../../../types';

import pt from '../../../assets/img/flags/mz.svg';
import en from '../../../assets/img/flags/um.svg';
import chrome from '../../../assets/img/chrome.jpg';
import { IReducers } from '../../../store';
import { getProfile, FREE_PAYMENT_QUERY } from '../../../graphql/queries';

const QUERY = getProfile;

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUser>();
    const [validationError, setValidationError] = useState('');
    const [language, setLanguage] = useState('pt');
    const [isOnLimit, setIsOnLimit] = useState(false);

    const [getProfile, { data: profileData, loading: profileLoading, error: profileError }] = useLazyQuery(QUERY);
    const [payResumeFree, { data: payResumeData, loading: resumeDataLoading, error: resumeDataError, reset }] =
        useMutation(FREE_PAYMENT_QUERY);
    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);
    // const user = useSelector((state: IReducers) => state.authReducer.user);


    const resume = useSelector((state: IReducers) => state.newResumesReducer);
    const resumeId = resume.id;

    useEffect(() => {
        if (profileData) {
            console.log(profileData.profile);
            setUser(profileData.profile);
        }

        if (profileData) {
            console.log(profileData);
        }


    }, [profileError, profileData]);

    useEffect(() => {
        if (payResumeData) {
            console.log(payResumeData.payResumeFree);
            dispatch({ type: 'SET_RESUME', resume: payResumeData.payResumeFree });
            navigate('/resumes/review');
        }

        if (resumeDataError) {
            console.log(resumeDataError);
        }

    }, [resumeDataLoading, resumeDataError]);

    const changeResumeLanguage = () => {
        setLanguage(language === 'pt' ? 'en' : 'pt');
    };

    useEffect(() => {
        if (language === 'en') {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'en',
            });
        } else {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'pt',
            });
        }
    }, [language]);

    useEffect(() => {
        // dispatch({
        //     type: 'SET_RESUME_HEADER',
        //     resumesHeader: { desc: 'Novo CV', position: 0 },
        // });
        getProfile();
    }, []);
    return (
        <>
            {(profileLoading || resumeDataLoading) && <Loader></Loader>}

            <div className='container'>

                <div className='tutorial'>
                    <div className='form-group'>


                        <>

                            <div>
                                {
                                    user?.recommendationStats?.credit ? <button className='button button-primary'
                                        onClick={() => {
                                            payResumeFree({
                                                variables: { resumeId },
                                            });
                                        }}
                                    >Pagar com créditos</button>
                                        :
                                        <></>
                                }
                                <ul className='font-primary'>
                                    <li >
                                        <span >Teu crédito atual:</span> {user?.recommendationStats?.credit}
                                    </li>
                                    <li>
                                        <span>Pessoas que recomendaste:</span> {user?.recommendationStats?.recommendationQuant}
                                    </li>
                                </ul>
                            </div>
                            <div style={{ width: "200px", height: "2px", backgroundColor: "gray", margin: 15 }}></div>
                            <h2
                                style={{
                                    fontSize: '24px',
                                    color: 'green',
                                }}
                            >
                                Para ter o cv GRÁTIS siga as instruções:
                            </h2>
                            <ul className='list'>
                                {/* <li>
                                    <span className='bold'>Teu ID:</span> <span className='bold font-success'> {user?.loginType === 'email' ? user?.email?.substring(0, user?.email.indexOf('@')) : user?.phoneNumber}</span>
                                </li> */}


                                <li>
                                    Deves recomendar esta aplicação <span className='font-success'>cv.co.mz</span> para pelo menos 3 pessoas
                                </li>
                                <li>
                                    Devem colocar o teu ID  <span className='bold font-primary'> {user?.loginType === 'email' ? user?.email?.substring(0, user?.email.indexOf('@')) : user?.phoneNumber}</span> logo depois de criar a conta
                                </li>
                                <li>
                                    Cada Recomendação vale um crédito
                                </li>
                            </ul>
                            <p>
                                <br />
                                Contacte o suporte pelo whatsapp se tiver dúvidas:
                            </p>
                            <ul>
                                <li>
                                    <i
                                        className='fa fa-message'
                                        style={{ color: '#395' }}
                                    ></i>{' '}
                                    <a
                                        style={{ color: '#395', textDecoration: 'none' }}
                                        href='https://wa.me/message/2SGBH7QH2YVUO1'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {' '}
                                        +258 84 614 5740{' '}
                                    </a>
                                </li>
                                {/* 
                                    <li>
                                      
                                        clique para <b>escolher a secção</b>
                                    </li> */}
                            </ul>
                        </>

                        <div className='error'>{validationError}</div>
                    </div>



                    <div className='row'>
                        {/* <button
                            className='button button-success'
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            Atualizar
                        </button> */}
                        <button
                            className='button button-primary'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/resumes/payment');
                            }}
                        >
                            Escolher outro plano
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
