import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { IUser } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../graphql/queries';
import { IReducers } from '../../store';

const QUERY = getUsers;

const Users: React.FC = () => {
    const [getResumes, { data, loading, error }] = useLazyQuery(QUERY);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);

    const viewUserResumes = (user: IUser) => {
        // dispatch({ type: 'SET_RESUME', resume });
        // dispatch({
        //     type: 'SET_ABOUT_PHOTO',
        //     aboutPhoto: `${API_URL}/resume/photos/${resume.id}.jpg?${
        //         Math.random() * 1000000
        //     }`,
        // });

        navigate(`/users/${user.id}`);
    };

    const logout = () => {
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });
    };
    useEffect(() => {
        getResumes();
    }, []);

    useEffect(() => {
        // console.log(data);
        if (data) {
            setTotal(data.users.length)
            console.log(data.users);

        }
    }, [data]);

    return (
        <>
            <div className='dashboard'>
                <div className='logout' onClick={() => {
                    navigate('/')
                }}>
                    <i className='fa fa-home'></i>
                </div>
                <h1>{error && error.message}</h1>

                <h1>{loading && 'Loading...'}</h1>

                <div className='body'>
                    <h1>List of Users's</h1>
                    <h2>Total users: {total}</h2>

                    <div className='user-list'>
                        {data &&
                            data.users.map((user: IUser) => {
                                return (
                                    <div
                                        key={user.id}
                                        className='item'
                                        onClick={() => {
                                            viewUserResumes(user);
                                        }}
                                    >
                                        <div className='desc'>
                                            {user.firstName +
                                                ' ' +
                                                user.lastName}
                                        </div>
                                        <div className='date'>
                                            {/* {new Date(
                                                parseInt(resume.createdAt + '')
                                            ).toDateString()} */}
                                            {user.email}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Users;
