import React from 'react';

// import { Container } from './styles';

const CompanyInfo: React.FC = () => {
    return (
        <>
            <div className='copyright'>
                <div>
                    <a target={'_blank'} href='https://cv.co.mz/terms.html'>
                        Termos de uso
                    </a>{' '}
                    |{' '}
                    <a target={'_blank'} href='https://cv.co.mz/policies.html'>
                        Privacidade
                    </a>
                </div>
                cv.co.mz © 2023 Todos os Direitos Reservados
            </div>
        </>
    );
};

export default CompanyInfo;
