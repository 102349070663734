import { gql } from '@apollo/client';

export const updateResumeMutation = gql`
    mutation UpdateResume($id: ID!, $data: ResumeInput!) {
        updateResume(id: $id, data: $data) {
            id
            firstName
            profession
            aboutMe
            lastName
            layout
            email
            phoneNumber
            hobbies
            gender
            nationality
            birthDay
            drivingLicense
            address
            resumeLanguage
            paymentDate
            canEditName
            sections {
                header
                elements {
                    name
                    desc
                    start
                    end
                    details
                }
            }
            skills {
                header
                elements {
                    desc
                    level
                }
            }
        }
    }
`;

export const uploadPhotoMutation = gql`
    mutation uploadPhoto($file: Upload!, $resumeId: String!) {
        uploadPhoto(file: $file, resumeId: $resumeId)
    }
`;

export const recommendation = gql`
    mutation Recommendation($id: ID!) {
        recommendation(id: $id)
    }
`;
