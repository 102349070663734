import { gql, useMutation } from '@apollo/client';
import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';

import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from '../../../utils/cropImage';
import { uploadPhotoMutation } from '../../../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../store';
import { useNavigate } from 'react-router-dom';

const Photo = () => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [cropedImageUrl, setCropedImageUrl] = useState('');
    const [cropedPhoto, setCropedPhoto] = useState<Blob | null>();
    const [showCropedImage, setShowCropedImage] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const resumeId = useSelector<IReducers>(
        (state) => state.newResumesReducer.id
    );
    const [uploadPhoto, { data, loading, error, reset }] =
        useMutation(uploadPhotoMutation);

    const onCropComplete = useCallback(
        (croppedArea: Area, croppedAreaPixels: Area) => {
            setCroppedAreaPixels(croppedAreaPixels);
        },
        []
    );

    const cropImage = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const { file, url } = await getCroppedImg(imageUrl, croppedAreaPixels);
        setCropedImageUrl(url as string);
        setShowCropedImage(true);
        setCropedPhoto(file);
    };

    const renderSelectedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            setImageUrl(url);
        }
    };

    const blobToFile = (theBlob: Blob, fileName: string): File => {
        let b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b as File;
    };

    const resizeFile = (file: File): Promise<File> =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                'JPEG',
                70,
                0,
                (file) => {
                    console.log(file);

                    resolve(file as File);
                },
                'file',
                300,
                300
            );
        });

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Editar foto', position: 0 },
        });
    }, []);

    const finishPhotoEdit = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        if (cropedPhoto) {
            dispatch({
                type: 'SET_ABOUT_PHOTO',
                aboutPhoto: URL.createObjectURL(cropedPhoto),
            });

            const resizedPhoto = await resizeFile(
                blobToFile(cropedPhoto, 'NewFile')
            );

            uploadPhoto({
                variables: { file: resizedPhoto, resumeId: resumeId },
            });

            navigate('/resumes/about');
        } else {
            console.error('No file to upload');
        }
    };

    useEffect(() => {
        if (error) {
            console.log(error);
        }
        if (data) {
            console.log(data);
        }
    }, [data, error]);

    return (
        <div className='photo'>
            <div className='croped-photo'>
                {cropedImageUrl && showCropedImage && (
                    <img src={cropedImageUrl} />
                )}
                <div className='form-group'>
                    <br />

                    <button
                        className='button button-primary'
                        onClick={(e) => {
                            e.preventDefault();
                            setShowCropedImage(false);
                        }}
                        hidden={!showCropedImage}
                    >
                        Modificar
                    </button>
                    <br />
                    <button
                        className='button button-success'
                        hidden={!showCropedImage}
                        onClick={finishPhotoEdit}
                    >
                        Concluir
                    </button>
                </div>
            </div>

            {imageUrl ? (
                <div className='crop-container' hidden={showCropedImage}>
                    <div className=' change-photo'>
                        <label
                            className='button button-primary'
                            htmlFor='file-input'
                        >
                            Escolher outra
                        </label>
                        <input
                            hidden
                            id='file-input'
                            type='file'
                            accept='image/png, image/jpg, image/jpeg'
                            onChange={renderSelectedFile}
                        />
                    </div>
                    <Cropper
                        image={imageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 5}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            ) : (
                <div hidden={showCropedImage}>
                    <div className='form-group'>
                        <label>Click no botão abaixo e escolha uma foto</label>
                    </div>
                    <input
                        className='file-input'
                        type='file'
                        accept='image/png, image/jpg, image/jpeg'
                        onChange={renderSelectedFile}
                    />
                    <br />
                    <br />
                    <br />
                    <div className='form-group'>
                        <button
                            className='button button-danger'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/resumes/about');
                            }}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            )}

            {imageUrl && !showCropedImage && (
                <div className='controls'>
                    <input
                        type='range'
                        min={1}
                        max={3}
                        step={0.2}
                        value={zoom}
                        onChange={(e) => {
                            setZoom(parseFloat(e.target.value));
                            console.log(e.target.value);
                        }}
                        className='slider'
                    ></input>
                    <button
                        className='button button-primary'
                        onClick={cropImage}
                    >
                        Recortar
                    </button>
                </div>
            )}
        </div>
    );
};

export default Photo;
