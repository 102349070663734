import { IElement, IResume, ISection } from '../types';

export interface IAction {
    type: string;
    resumes: IResume[];
}

export interface IResumeState {
    resumes: IResume[];
}

const INITIAL_STATE: IResumeState = {
    resumes: [],
};

export function resumesReducer(
    state: IResumeState = INITIAL_STATE,
    action: IAction
) {
    switch (action.type) {
        case 'SET_RESUMES':
            return {
                ...state,
                resumes: action.resumes,
            } as IResumeState;

        default:
            return state;
    }
}
