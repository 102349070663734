
import { Provider, useSelector } from 'react-redux';
import store, { IReducers } from './store';
import Routes from './routes';
import './assets/css/style.css';

function App() {
    return (
        <div className='main'>
            <Provider store={store}>
                <Routes>
                </Routes>
            </Provider>
        </div>
    );
}

export default App;
