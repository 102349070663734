interface IResumesHeader {
    desc: string;
    position: number;
}
export interface IAction {
    type: string;
    resumesHeader: IResumesHeader;
    apiUrl: string;
    resumePdfUrl: string;
    showMenu: boolean;
    resumeLanguage: string;
    templates: any;
}
export interface IUtilState {
    resumesHeader: IResumesHeader;
    apiUrl: string;
    resumePdfUrl: string;
    resumePhotoUrl: string;
    showMenu: boolean;
    templates: any;
    text: any;
}

const portuguese = {
    download: 'Download',
    edit: 'Editar',
    delete: 'Apagar',
    template: 'Mudar Modelo',
    review: 'Revisão',
    addNew: 'Adicionar Novo',
    save: 'Salvar',
    next: 'Próximo',
    previous: 'Anterior',
    editing: 'Editando...',
    viewCV: 'Ver CV',
    createCV: 'Criar CV',
    viewAllTemplates: 'Ver Todos',

    close: 'fechar',
    use: 'usar',
    choose: 'Escolher',
    continue: 'Continuar',

    cancel: 'voltar',
    photo: 'Foto',

    aboutLabel: 'Diga como tu és',
    profilePlaceholder:
        'Ex: Sou um jovem autoditata a procura de uma oportunidade...',

    school: 'Escola ou Instituição',
    schoolPlaceholder: 'Onde Estudou?',
    grade: 'Classe ou Curso',
    gradePlaceholder: 'Ex: 10 classe, Contabilidade',

    courseLocation: 'Instituição ou local',
    courseLocationPlaceholder: 'Onde fez o Curso?',
    courseName: 'Nome do curso',
    courseNamePlaceholder: 'Ex: Primeiros Socoros',
    courseDetails: 'Detalhes do Curso',
    courseDetailsPlaceholder: 'Lições, tópicos, etc...',

    experienceLocation: 'Empresa ou local',
    experienceLocationPlaceholder: 'Onde trabalhou?',
    experienceName: 'Ocupação',
    experiencePlaceholder: 'Ex: Carpinteiro, Contabilista',
    experienceDetails: 'Funções e Competências',
    experienceDetailsPlaceholder: 'Detalhes do que fazia neste emprego',

    language: 'Língua',
    languageLevel: 'Escolha o nível',

    skill: 'Habilidade ou Competência',
    skillPlaceholder: 'Ex: Reboco',
    skillLevel: 'Escolha o nível',

    referenceRole: 'Posição e empresa',
    referenceRolePlaceholder: 'Ex: Director Executivo da Nokia',
    referenceName: 'Nome',
    referenceNamePlaceholder: 'ex: Jorge Smith',
    referenceDetails: 'Detalhes',
    referenceDetailsPlaceholder: 'Contaco, email, etc...',

    menu: {
        education: 'Perfil Acadêmico',
        course: 'Cursos',
        experience: 'Experiência Profissional',
        language: 'Línguas',
        personalInfo: 'Informação Pessoal',
        aboutMe: 'Foto e Sobre Mim',
        skills: 'Habilidades e Competências',
        references: 'Referências',
        template: 'Modelo',
    },

    personalInfo: {
        firstName: 'Nome',
        lastName: 'Apelido',
        email: 'Email',
        Portfolio: 'Portfolio',
        profession: 'Profissão',
        address: 'Endereço',
        drivingLicense: 'Carta de condução',
        maritalStatus: 'Estado Civil',
        gender: 'Gênero',
        nationality: 'Nacionalidade',
        birthDay: 'Data de Nascimento',
        contact: 'Contactos',
    },

    start: 'Início',
    startPlaceholder: 'ex: 2011',

    end: 'Fim',
    endPlaceholder: 'ex: 2014',
};

const english = {
    download: 'Download',
    edit: 'Edit',
    delete: 'Delete',
    template: 'Change Template',
    review: 'Review',
    addNew: 'Add New',
    save: 'Save',
    next: 'Next',
    previous: 'Previous',
    editing: 'Editing...',
    viewCV: 'View CV',
    createCV: 'Create CV',
    viewAllTemplates: 'View All',

    close: 'close',
    use: 'use',
    choose: 'Choose',
    continue: 'Continue',
    cancel: 'cancel',
    photo: 'Photo',

    aboutLabel: 'Your Profile',
    profilePlaceholder: 'Your profile information',

    school: 'School',
    schoolPlaceholder: 'Your school name',
    grade: 'Grade',
    gradePlaceholder: 'e.g. IT Engeneering',

    courseLocation: 'Institution or Location',
    courseLocationPlaceholder: 'Where did you take the course?',
    courseName: 'Course Name',
    courseNamePlaceholder: 'e.g Plumbing',
    courseDetails: 'Course Details',
    courseDetailsPlaceholder: 'Topics, Lessons, etc...',

    experienceLocation: 'Company or location',
    experienceLocationPlaceholder: '',
    experienceName: 'Role',
    experiencePlaceholder: '',
    experienceDetails: 'Experience Details',
    experienceDetailsPlaceholder: '',

    language: 'Language',
    languageLevel: 'Level',

    skill: 'Skill',
    skillPlaceholder: 'e.g. Excel',
    skillLevel: 'Skill level',

    referenceRole: 'Company and Role',
    referenceRolePlaceholder: 'e.g. CEO of Nokia',
    referenceName: 'Name',
    referenceNamePlaceholder: 'e.g. Jorge Smith',
    referenceDetails: 'Experience Details',
    referenceDetailsPlaceholder: 'Contact, email, etc...',

    menu: {
        education: 'Education',
        course: 'Courses',
        experience: 'Experience',
        language: 'Languages',
        personalInfo: 'Personal Info',
        aboutMe: 'Photo and Profile',
        skills: 'Skills',
        references: 'References',
        template: 'Template',
    },

    personalInfo: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        email: 'Email',
        Portfolio: 'Portfolio',
        profession: 'Profession',
        address: 'Address',
        drivingLicense: 'Driving License',
        maritalStatus: 'Martial Status',
        gender: 'Gender',
        nationality: 'Nationality',
        birthDay: 'Birth Date',
        contact: 'Contacts',
    },

    start: 'Start Date',
    startPlaceholder: 'e.g. 2011',

    end: 'End Date',
    endPlaceholder: 'e.g. 2014',
};

const INITIAL_STATE: IUtilState = {
    resumesHeader: { desc: '', position: 0 },
    apiUrl: 'https://api.cv.co.mz',
    resumePdfUrl: 'https://api.cv.co.mz',
    resumePhotoUrl: 'https://cv-co-mz.s3.amazonaws.com/resume-photo/',
    showMenu: false,
    templates: {},
    text: portuguese,
};

function utilReducer(state = INITIAL_STATE, action: IAction): IUtilState {
    switch (action.type) {
        case 'SET_RESUME_EDIT_LANGUAGE':
            if (action.resumeLanguage === 'en') {
                return { ...state, text: english };
            } else {
                return { ...state, text: portuguese };
            }
        case 'SET_RESUME_HEADER':
            return { ...state, resumesHeader: action.resumesHeader };
        case 'SET_API_URL':
            return { ...state, apiUrl: action.apiUrl };
        case 'SET_RESUME_PDF_URL':
            return { ...state, resumePdfUrl: action.resumePdfUrl };
        case 'SET_TEMPLATES':
            return { ...state, templates: action.templates };
        case 'SHOW_MENU':
            return { ...state, showMenu: action.showMenu };
        default:
            return state;
    }
}

export default utilReducer;
