import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Language from '../pages/resume/create/Language';
import Experience from '../pages/resume/create/Expreience';
import Skill from '../pages/resume/create/Skill';
import PersonalInfo from '../pages/resume/create/PersonalInfo';
import Review from '../pages/resume/create/Review';
import New from '../pages/resume/create/New';
import CreateResume from '../pages/resume/create';
import Education from '../pages/resume/create/Education';
import List from '../pages/resume/List';
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../store';
import { IUser } from '../types';
import Login from '../pages/Login';
import ResumeAbout from '../pages/resume/create/About';

import {
    ApolloProvider,
    InMemoryCache,
    ApolloClient,
    ApolloLink,
    RequestHandler,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import Photo from '../pages/resume/create/Photo';
import Course from '../pages/resume/create/Course';
import Template from '../pages/resume/create/Template';
import References from '../pages/resume/create/References';
import Users from '../pages/users/Users';
import UserResumes from '../pages/users/UserResumes';
import Register from '../pages/Register';
import Welcome from '../pages/Welcome';
import Payment from '../pages/resume/payment/Payment';
import CheckToken from '../components/CheckToken';
import axios from 'axios';
import RecoverPassword from '../pages/RecoverPassword';
import NewPasswordSuccess from '../pages/NewPasswordSuccess';
import NewResumeTemplate from '../pages/resume/create/NewResumeTemplate';
import Profile from '../pages/resume/create/Profile';
import Admin from '../pages/resume/payment/Admin';
import Emola from '../pages/resume/payment/Emola';
import MPesa from '../pages/resume/payment/MPesa';
import Free from '../pages/resume/payment/Free';

const RoutesComponent: React.FC = () => {
    const dispatch = useDispatch();
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    if (process.env.NODE_ENV === 'development') {
        dispatch({ type: 'SET_API_URL', apiUrl: 'http://localhost:3001' });
        dispatch({
            type: 'SET_RESUME_PDF_URL',
            resumePdfUrl: 'http://localhost:3001',
        });
    }

    const getTemplates = () => {
        axios
            .get(`${API_URL}/templates`)
            .then((res) => {
                dispatch({
                    type: 'SET_TEMPLATES',
                    templates: res.data.templates,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const checkToken = () => {
        const userTokenString = localStorage.getItem('token');
        if (userTokenString) {
            const storedUser: IUser = JSON.parse(userTokenString);
            const { exp }: { exp: number } = jwtDecode(storedUser.token + '');
            const loginUrl = window.location.origin;

            if (Date.now() >= exp * 1000) {
                dispatch({ type: 'SET_USER', user: null });
                console.error('Token Expired');
            } else {
                dispatch({ type: 'SET_USER', user: storedUser });
            }
        } else {
            dispatch({ type: 'SET_USER', user: null });
        }
    };

    const validateToken = () => {
        const userTokenString = localStorage.getItem('token');
        if (userTokenString) {
            const storedUser: IUser = JSON.parse(userTokenString);
            const { exp }: { exp: number } = jwtDecode(storedUser.token + '');

            if (Date.now() >= exp * 1000) {
                dispatch({ type: 'SET_USER', user: null });
                console.error('Token Expired');
            }
        } else {
            dispatch({ type: 'SET_USER', user: null });
        }
    };

    const user = useSelector((state: IReducers) => state.authReducer.user);

    const link = createUploadLink({
        uri: `${API_URL}/graphql`,
        headers: {
            authorization: `Bearer ${user?.token}`,
        },
    }) as any;

    const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache({
            addTypename: false,
        }),
        uri: `${API_URL}/graphql`,
        headers: {
            authorization: `Bearer ${user?.token}`,
        },
    });

    useEffect(() => {
        getTemplates();
        checkToken();
        window.addEventListener('focus', () => {
            validateToken();
        });
    }, []);

    return (
        <>
            {user ? (
                <ApolloProvider client={client}>
                    <Router>
                        <Routes>
                            <Route path='/resumes/list' element={<List />} />
                            <Route path='/resumes/new' element={<New />} />
                            <Route path='/profile' element={<Profile />} />

                            <Route path='/resumes' element={<CreateResume />}>
                                <Route path='payment' element={<Payment />} />
                                <Route path='payment/admin' element={<Admin />} />
                                <Route path='payment/emola' element={<Emola />} />
                                <Route path='payment/mpesa' element={<MPesa />} />
                                <Route path='payment/free' element={<Free />} />

                                <Route
                                    path='new-resume-template'
                                    element={<NewResumeTemplate />}
                                />

                                <Route
                                    path='education'
                                    element={<Education />}
                                />

                                <Route path='course' element={<Course />} />
                                <Route path='template' element={<Template />} />
                                <Route
                                    path='reference'
                                    element={<References />}
                                />

                                <Route path='photo' element={<Photo />} />
                                <Route path='about' element={<ResumeAbout />} />

                                <Route
                                    path='experience'
                                    element={<Experience />}
                                />
                                <Route path='skill' element={<Skill />} />
                                <Route path='review' element={<Review />} />
                                <Route
                                    path='personal-info'
                                    element={<PersonalInfo />}
                                />
                                <Route path='language' element={<Language />} />
                            </Route>

                            <Route path='/' element={<Dashboard />} />
                            <Route path='/about' element={<About />} />

                            <Route path='/users' element={<Users />} />
                            <Route
                                path='/users/:userId'
                                element={<UserResumes />}
                            />
                            <Route path='/register' element={<Welcome />} />
                            <Route
                                path='/reset-password'
                                element={<NewPasswordSuccess />}
                            />
                        </Routes>
                    </Router>
                </ApolloProvider>
            ) : (
                <Router>
                    <Routes>
                        <Route path='/*' element={<CheckToken />} />
                        <Route path='/' element={<Login />} />
                        <Route
                            path='/reset-password'
                            element={<RecoverPassword />}
                        />

                        <Route path='register' element={<Register />} />
                    </Routes>
                </Router>
            )}
        </>
    );
};

export default RoutesComponent;
