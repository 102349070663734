import React from 'react';

// import { Container } from './styles';
interface Props {
    text: string;
}
const LoaderWithText: React.FC<Props> = ({ text }) => {
    return (
        <>
            <div className='loader-conteiner'>
                <div style={{ fontSize: '24px', color: '#2488c9' }}>{text}</div>
            </div>
        </>
    );
};

export default LoaderWithText;
