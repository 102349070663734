/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { IReducers } from '../../../store';
import mpesaLogo from '../../../assets/img/m-pesa-logo-2d.png';
import { ADMIN_PAYMENT_QUERY as QUERY } from '../../../graphql/queries'


const Admin: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [voucherCode, setVoucherCode] = useState('');

    const [validationError, setValidationError] = useState('');
    const [payResumeByVoucher, { data, loading, error }] = useMutation(QUERY);

    const resume = useSelector((state: IReducers) => state.newResumesReducer);
    const resumeId = resume.id;

    const [isExpired, setIsExperid] = useState(resume.resumeType !== 'new');

    useEffect(() => {
        if (data) {
            if (data.payResumeVoucher?.code) {
                console.log(data);
                setValidationError('Cupom inválido');
            } else {
                navigate('/');
            }
        }
        if (error) {
            console.log(error);
        }
    }, [data, error]);

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Pagamento', position: 0 },
        });
    }, []);
    return (
        <>
            {loading && <Loader></Loader>}
            <div className='container'>
                <div
                    className='over-close'
                    onClick={() => {
                        navigate('/resumes/review');
                    }}
                ></div>
                <div className='choose-language'>
                    <div className='form-group'>
                        {isExpired && (
                            <div
                                style={{
                                    backgroundColor: 'orange',
                                    color: 'white',
                                }}
                            >
                                Pagamento Expirou
                            </div>
                        )}

                        <br />
                        <input
                            type='text'
                            placeholder='Insira o código aqui'
                            value={voucherCode}
                            onChange={(e) => {
                                setVoucherCode(e.target.value);
                            }}
                        />
                        <div className='error'>{validationError}</div>
                    </div>

                    <div className='form-group'>
                        <button
                            className='button button-primary'
                            onClick={async (e) => {
                                e.preventDefault();
                                // navigate('/resumes/education', {
                                //     state: { resume },
                                // });
                                if (voucherCode.trim().length === 0) {
                                    setValidationError('Inista o código do cupon')
                                } else {
                                    await payResumeByVoucher({
                                        variables: { resumeId, voucherCode },
                                    });
                                }

                            }}
                        >
                            Continuar
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Admin;
