import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../store';
import { useMutation } from '@apollo/client';
import { updateResumeMutation } from '../../../graphql/mutations';
import { getAtualResume } from '../../../utils';
import { IResume } from '../../../types';
import Menu from '../../../components/Menu';
import Loader from '../../../components/Loader';

const QUERY = updateResumeMutation;

const Resume: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resumeHeader = useSelector(
        (state: IReducers) => state.utilReducer.resumesHeader
    );

    const newResume = useSelector(
        (state: IReducers) => state.newResumesReducer
    );
    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    )
    const showMenu = useSelector(
        (state: IReducers) => state.utilReducer.showMenu
    );

    const [updateResume, { data, loading, error, reset }] = useMutation(QUERY);

    const resumeData: IResume = getAtualResume(newResume);

    const getPositions = () => {
        let positions = [];

        for (let i = 1; i < 6; i++) {
            if (resumeHeader.position === i) {
                positions.push(
                    <div key={i} className='position selected'></div>
                );
            } else {
                positions.push(<div key={i} className='position'></div>);
            }
        }
        return positions;
    };

    const saveAndPreview = async () => {
        updateResume({
            variables: {
                id: newResume.id,
                data: resumeData,
            },
        });
    };

    useEffect(() => {
        if (newResume.id === '') {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (data) {
            navigate('/resumes/review');
        }

        if (error) {
        }
    }, [data, error]);

    return (
        <>
            {loading && <Loader></Loader>}

            {showMenu && <Menu></Menu>}

            <div className='navbar'>
                {window.location.pathname === '/resumes/review' ? (
                    <div
                        className='go-back'
                        onClick={() => {
                            dispatch({ type: 'SHOW_MENU', showMenu: true });
                        }}
                    >
                        <i className='fa fa-edit'></i>
                    </div>
                ) : (
                    <div
                        className='go-back'
                        onClick={() => {
                            saveAndPreview();
                        }}
                    >
                        {TEXT.viewCV}
                    </div>
                )}
                <div
                    className='resume-header'
                    onClick={() => {
                        dispatch({ type: 'SHOW_MENU', showMenu: true });
                    }}
                >
                    <h3>{resumeHeader.desc}</h3>
                    <div className='positions'>
                        {resumeHeader.position === 0 || getPositions()}
                    </div>
                </div>
                {window.location.pathname === '/resumes/review' ?
                    <div
                        className='close'

                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <i className='fa fa-circle-xmark'></i>

                    </div> :
                    <div
                        className='go-back'
                        onClick={() => {
                            saveAndPreview();
                        }}
                    >
                        <i className='fa fa-eye'> </i>
                    </div>
                }

            </div>
            <div className='resumes'>
                <Outlet />
            </div>
        </>
    );
};

export default Resume;
