import { v4 as uuidv4 } from 'uuid';

import {
    IElement,
    ILanguage,
    IPersonalData,
    IResume,
    ISection,
    ISkill,
    ISkillElement,
} from '../types';

export interface IAction {
    type: string;
    userId: string;
    layout: string;
    profession: string;
    aboutMe: string;
    firstName: string;
    lastName: string;
    email: string;
    drivingLicense: string;
    phoneNumber: string;
    gender: string;
    maritalStatus: string;
    birthDay: string;
    nationality: string;
    address: String;
    education: IElement;
    educations: IElement[];
    experience: IElement;
    experiences: IElement[];
    reference: IElement;
    references: IElement[];
    course: IElement;
    courses: IElement[];
    language: ILanguage;
    languages: ILanguage[];
    skill: ISkillElement;
    skills: ISkillElement[];
    resumeLanguage: string;
    paymentDate: Date;
    canEditName: boolean;
    personalInfo: IPersonalData;
    resume: IResume;
    aboutPhoto: string;
}

export interface INewResumeState {
    id: string;
    userId: string;
    layout: string;
    profession: string;
    aboutMe: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    gender: string;
    maritalStatus: string;
    drivingLicense: string;
    birthDay: string;
    portfolio: string;
    nationality: string;
    address: string;
    hobbies: string[];
    educations: IElement[];
    experiences: IElement[];
    references: IElement[];
    courses: IElement[];
    languages: ILanguage[];
    skills: ISkillElement[];

    resumeLanguage: string;
    paymentDate?: Date;
    canEditName: boolean;
    aboutPhoto: string;
    resumeType: string;
    downloads: number;
    validUntil: Date;
}

const INITIAL_STATE: INewResumeState = {
    id: '',
    userId: uuidv4(),
    layout: uuidv4(),
    aboutMe: '',
    portfolio: '',
    profession: '',
    hobbies: [],
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    maritalStatus: '',
    drivingLicense: '',
    birthDay: '',
    nationality: '',
    address: '',
    languages: [],
    educations: [],
    experiences: [],
    references: [],
    courses: [],
    skills: [],
    // paymentDate: new Date(),
    resumeLanguage: 'pt',
    aboutPhoto: '',
    canEditName: true,
    resumeType: 'new',
    downloads: 0,
    validUntil: new Date(),
};

function setResume(resume: IResume): INewResumeState {
    const newResumeState: INewResumeState = {
        ...resume,
        phoneNumber: resume.phoneNumber.join(','),
        educations: resume.sections[0].elements,
        experiences: resume.sections[1].elements,
        courses: resume?.sections[2]?.elements
            ? resume.sections[2].elements
            : [],
        references: resume?.sections[3]?.elements
            ? resume.sections[3].elements
            : [],
        skills: resume.skills[0].elements,
        aboutPhoto: '',
        resumeLanguage: resume.resumeLanguage || 'pt',
    };

    return newResumeState;
}

export function newResumesReducer(
    state: INewResumeState = INITIAL_STATE,
    action: IAction
) {
    switch (action.type) {
        case 'SET_RESUME':
            return setResume(action.resume);
        case 'SET_RESUME_LANGUAGE':
            return {
                ...state,
                resumeLanguage: action.resumeLanguage,
            } as INewResumeState;
        case 'ADD_PERSONAL_INFO':
            return {
                ...state,
                ...action.personalInfo,
            } as INewResumeState;
        case 'SET_EDUCATIONS':
            return {
                ...state,
                educations: [...action.educations],
            } as INewResumeState;
        case 'ADD_EDUCATION':
            return {
                ...state,
                educations: [...state.educations, action.education],
            } as INewResumeState;
        case 'EDIT_EDUCATION':
            return {
                ...state,
                educations: [
                    ...state.educations.map((education) => {
                        if (education.id === action.education.id) {
                            return action.education;
                        }
                        return education;
                    }),
                ],
            } as INewResumeState;
        case 'DELETE_EDUCATION':
            return {
                ...state,
                educations: state.educations.filter((education) => {
                    return education.id !== action.education.id;
                }),
            } as INewResumeState;

        case 'EDIT_EXPERIENCE':
            return {
                ...state,
                experiences: [
                    ...state.experiences.map((experience) => {
                        if (experience.id === action.experience.id) {
                            return action.experience;
                        }
                        return experience;
                    }),
                ],
            } as INewResumeState;
        case 'SET_EXPERIENCES':
            return {
                ...state,
                experiences: [...action.experiences],
            } as INewResumeState;
        case 'ADD_EXPERIENCE':
            return {
                ...state,
                experiences: [...state.experiences, action.experience],
            } as INewResumeState;
        case 'DELETE_EXPERIENCE':
            return {
                ...state,
                experiences: state.experiences.filter((experience) => {
                    return experience.id !== action.experience.id;
                }),
            } as INewResumeState;

        case 'EDIT_COURSE':
            return {
                ...state,
                courses: [
                    ...state.courses.map((course) => {
                        if (course.id === action.course.id) {
                            return action.course;
                        }
                        return course;
                    }),
                ],
            } as INewResumeState;
        case 'SET_COURSES':
            return {
                ...state,
                courses: [...action.courses],
            } as INewResumeState;
        case 'ADD_COURSE':
            return {
                ...state,
                courses: [...state.courses, action.course],
            } as INewResumeState;
        case 'DELETE_COURSE':
            return {
                ...state,
                courses: state.courses.filter((course) => {
                    return course.id !== action.course.id;
                }),
            } as INewResumeState;
        case 'EDIT_REFERENCE':
            return {
                ...state,
                references: [
                    ...state.references.map((reference) => {
                        if (reference.id === action.reference.id) {
                            return action.reference;
                        }
                        return reference;
                    }),
                ],
            } as INewResumeState;

        case 'ADD_REFERENCE':
            return {
                ...state,
                references: [...state.references, action.reference],
            } as INewResumeState;
        case 'SET_REFERENCES':
            return {
                ...state,
                references: [...action.references],
            } as INewResumeState;
        case 'DELETE_REFERENCE':
            return {
                ...state,
                references: state.references.filter((reference) => {
                    return reference.id !== action.reference.id;
                }),
            } as INewResumeState;
        case 'SET_LANGUAGES':
            return {
                ...state,
                languages: [...action.languages],
            } as INewResumeState;
        case 'ADD_LANGUAGE':
            return {
                ...state,
                languages: [...state.languages, action.language],
            } as INewResumeState;
        case 'EDIT_LANGUAGE':
            return {
                ...state,
                languages: state.languages.map((language) => {
                    if (language.id === action.language.id) {
                        return action.language;
                    }
                    return language;
                }),
            } as INewResumeState;
        case 'DELETE_LANGUAGE':
            return {
                ...state,
                languages: state.languages.filter((language) => {
                    return language.id !== action.language.id;
                }),
            } as INewResumeState;
        case 'SET_SKILLS':
            return {
                ...state,
                skills: [...action.skills],
            } as INewResumeState;
        case 'ADD_SKILL':
            return {
                ...state,
                skills: [...state.skills, action.skill],
            } as INewResumeState;
        case 'EDIT_SKILL':
            return {
                ...state,
                skills: state.skills.map((skill) => {
                    if (skill.desc === action.skill.id) {
                        return action.skill;
                    }
                    return skill;
                }),
            } as INewResumeState;
        case 'DELETE_SKILL':
            return {
                ...state,
                skills: state.skills.filter((skill) => {
                    return skill.desc !== action.skill.desc;
                }),
            } as INewResumeState;

        case 'DISABLE_EDIT_NAME':
            return {
                ...state,
                canEditName: false,
            } as INewResumeState;
        case 'ENABLE_EDIT_NAME':
            return {
                ...state,
                canEditName: true,
            } as INewResumeState;
        case 'SET_EDIT_NAME':
            return {
                ...state,
                canEditName: action.canEditName,
            } as INewResumeState;

        case 'SET_RESUME_ABOUT':
            return {
                ...state,
                aboutMe: action.aboutMe,
            } as INewResumeState;

        case 'SET_ABOUT_PHOTO':
            return {
                ...state,
                aboutPhoto: action.aboutPhoto,
            } as INewResumeState;
        case 'SET_TEMPLATE':
            return {
                ...state,
                layout: action.layout,
            } as INewResumeState;
        default:
            return state;
    }
}
