import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IResume } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserResumes } from '../graphql/queries';
import { IReducers } from '../store';
import Loader from '../components/Loader';

import gradient from '../assets/img/templates/gradient.png';
import firstTemplate from '../assets/img/templates/first-template.png';
import professional from '../assets/img/templates/professional.png';
import modernResume from '../assets/img/templates/modern-resume.png';
import ejs from '../utils/ejs';
import { getFormatedDate } from '../utils';
import pt from '../../src/assets/img/flags/mz.svg';
import en from '../../src/assets/img/flags/um.svg';

const QUERY = getUserResumes;
const DEFAULT_TEMPLATE = 'professional';

const Dashboard: React.FC = () => {
    const [getResumes, { data, loading, error }] = useLazyQuery(QUERY);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    const RESUME_PHOTO_URL = useSelector(
        (state: IReducers) => state.utilReducer.resumePhotoUrl
    );

    const user = useSelector((state: IReducers) => state.authReducer.user);
    const TEMPLATES = useSelector(
        (state: IReducers) => state.utilReducer.templates
    );
    const viewResume = (resume: IResume) => {
        dispatch({ type: 'SET_RESUME', resume });
        dispatch({
            type: 'SET_ABOUT_PHOTO',
            aboutPhoto: `${RESUME_PHOTO_URL}${resume.id}.jpg?${
                Math.random() * 1000000
            }`,
        });

        navigate('/resumes/review');
    };
    const logout = () => {
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });
    };

    const getBackgroundUrl = (template: string) => {
        switch (template) {
            case 'modern-resume':
                return `url(${modernResume})`;
            case 'first-template':
                return `url(${firstTemplate})`;
            case 'professional':
                return `url(${professional})`;
            case 'gradient':
                return `url(${gradient})`;
            default:
                return `url(${professional})`;
        }
    };

    const getResumeIframe = (newResume: IResume) => {
        let photoUrl = `${API_URL}/resume/photos/${newResume.id}.jpg`;
        let template = DEFAULT_TEMPLATE;
        const templateNames = Object.keys(TEMPLATES);
        for (let i = 0; i < templateNames.length; i++) {
            if (templateNames[i] === newResume.layout) {
                template = templateNames[i];
            }
        }
        const html = ejs.render(TEMPLATES[template], {
            resume: newResume,
            previewCondition: true,
            photoUrl: '',
            // photoUrl
        });
        const url = URL.createObjectURL(
            new Blob([html], { type: 'text/html' })
        );

        return url;
    };

    const getResumeType = (resume: IResume) => {
        if (resume.resumeType === 'new') {
            return 'resume-new';
        }
        if (!resume.validUntil) {
            return '';
        }
        const validUntil = new Date(
            parseInt(resume.validUntil.toString())
        ).getTime();

        if (validUntil < Date.now()) {
            return 'resume-expired';
        }

        return 'resume-valid';
    };

    const getResumeExpireDate = (resume: IResume) => {
        if (resume.resumeType === 'new') {
            return <span style={{ color: '#339dff' }}>{'CV Novo'}</span>;
        }

        if (!resume.validUntil) {
            return 'CV novo (Não pago)';
        }

        const validUntil = new Date(
            parseInt(resume.validUntil.toString())
        ).getTime();

        if (validUntil < Date.now()) {
            return (
                <span style={{ color: '##8f8e8e' }}>
                    {'Expirou aos ' +
                        getFormatedDate(
                            new Date(parseInt(resume.validUntil.toString()))
                        )}
                </span>
            );
        }

        return (
            <span style={{ color: '#46c829' }}>
                {'Válido até ' +
                    getFormatedDate(
                        new Date(parseInt(resume.validUntil.toString()))
                    )}
            </span>
        );
    };

    const getResumeLanguageFlag = (resume: IResume) => {
        switch (resume.resumeLanguage) {
            case 'pt':
                return pt;
            case 'en':
                return en;
            default:
                return pt;
        }
    };
    useEffect(() => {
        getResumes();
    }, []);

    useEffect(() => {}, [data]);

    return (
        <>
            {loading && <Loader></Loader>}
            <div className='dashboard'>
                <div className='logout'>
                    <i className='fa fa-user'></i>

                    <div className='user-menu'>
                        <div
                            className='menu-item'
                            onClick={() => {
                                navigate('/profile');
                            }}
                        >
                            Perfil
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Atualizar
                        </div>

                        <div className='menu-item' onClick={logout}>
                            Sair
                        </div>
                    </div>
                </div>
                <div
                    className='logo'
                    style={{
                        position: 'fixed',
                        zIndex: 3,
                        left: 20,
                        top: 20,
                        fontSize: '20px',
                    }}
                >
                    <span style={{ color: '#5e4f9f' }}>cv</span>.
                    <span style={{ color: '#2488c9' }}>co</span>.
                    <span style={{ color: '#2fb4ca' }}>mz</span>
                </div>
                <div className='vagas'>
                    <a
                        href='https://chat.whatsapp.com/L20aufJ8IovC0j1sUh1vqO'
                        target={'_blank'}
                    >
                        <button className='button button-success'>vagas</button>
                    </a>
                </div>
                {user?.email === 'simoeszunguze@gmail.com' && (
                    <button
                        className='button'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/users');
                        }}
                    >
                        <span>users</span>
                    </button>
                )}

                <div className='body'>
                    <h2> CV's do {user?.firstName}</h2>
                    <div className='resume-list'>
                        <div
                            className='new-resume'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/resumes/new');
                            }}
                        >
                            <div className='new-plus'>
                                <i className='fa fa-plus'></i>
                            </div>

                            <div className='new-text'>NOVO CV</div>
                        </div>
                        {data &&
                            data.resumes.map((resume: IResume) => {
                                return (
                                    <div key={resume.id} className='item'>
                                        {getResumeType(resume) ===
                                            'resume-valid' && (
                                            <i className='payment-checked fa fa-circle-check'></i>
                                        )}

                                        <div
                                            className='screenshoot'
                                            // style={{
                                            //     backgroundImage:
                                            //         getBackgroundUrl(
                                            //             resume.layout
                                            //         ),
                                            // }}
                                            onClick={() => {
                                                viewResume(resume);
                                            }}
                                        >
                                            <iframe
                                                className='resume-preivew'
                                                title='CV'
                                                src={getResumeIframe(resume)}
                                                width='800'
                                                height='1122'
                                            ></iframe>
                                            <div className='view-back'>
                                                <div className='view'>
                                                    <i className='fa fa-eye'></i>{' '}
                                                    VER
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tools'>
                                            <div className='desc'>
                                                <div className='name'>
                                                    {resume.firstName +
                                                        ' ' +
                                                        resume.lastName}
                                                </div>
                                                <div className='date'>
                                                    {getResumeExpireDate(
                                                        resume
                                                    )}

                                                    <span>
                                                        <img
                                                            src={getResumeLanguageFlag(
                                                                resume
                                                            )}
                                                            style={{
                                                                width: '16px',
                                                            }}
                                                            alt=''
                                                        />
                                                    </span>
                                                </div>
                                                <div
                                                    className={`resume-type ${getResumeType(
                                                        resume
                                                    )}`}
                                                ></div>
                                            </div>

                                            <div className='download'>
                                                <i className='fa fa-download'></i>{' '}
                                                <span>Download</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div
                    style={{
                        // backgroundColor: 'yellow',
                        padding: '10px',
                        marginTop: '20px',
                        color: 'orange',
                    }}
                >
                    Qualquer dúvida entre em contacto com a nossa equipa pelo
                    whatsapp:{' '}
                    <span style={{ color: '#006' }}>+258 86 435 2310</span>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
