import React, { useState } from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';

import jwt_decode from 'jwt-decode';
import { IUser } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../store';
import loginImage from '../assets/img/resume-login.svg';
import { useNavigate } from 'react-router-dom';
import LoaderWithText from '../components/LoaderWithText';
import CompanyInfo from '../components/CompanyInfo';
const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [wait, setWait] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const API_URL = useSelector((state: IReducers) => state.utilReducer.apiUrl);
    const navigate = useNavigate();

    const login = () => {
        setWait(true);
        setError('');
        axios
            .post(`${API_URL}/login`, {
                username,
                password,
            })
            .then((response) => {
                const accessToken = response.data.accessToken;
                let decoded: IUser = jwt_decode(accessToken);
                let user: IUser = { ...decoded, token: accessToken };
                console.log(user);
                dispatch({
                    type: 'SET_USER',
                    user,
                });
                localStorage.setItem('token', JSON.stringify(user));
                setWait(false);
            })
            .catch((error) => {
                console.log(error);

                if (error?.response?.status === 401) {
                    setError('Passowrd ou nome de usuário incorrectos');
                } else {
                    setError('Erro na conecção');
                }
                setWait(false);
            });
    };

    const responseGoogleSuccess = (response: any) => {
        axios
            .post(`${API_URL}/auth/google`, { tokenId: response.tokenId })
            .then((response) => {
                const accessToken = response.data.accessToken;
                let decoded: IUser = jwt_decode(accessToken);
                let user: IUser = { ...decoded, token: accessToken };
                // navigate('/register');

                dispatch({
                    type: 'SET_USER',
                    user,
                });
                localStorage.setItem('token', JSON.stringify(user));
                setWait(false);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    setError('Não autorizado a fazer login com google');
                } else {
                    setError('Erro na conecção');
                }
                setWait(false);

                console.error(error);
            });
    };

    const responseGoogleError = (response: any) => {
        setWait(false);
        console.log(response);
    };
    return (
        <>
            {wait && <LoaderWithText text='Autenticando...'></LoaderWithText>}
            <div className='login-container'>
                <div className='ilustration'>
                    <div className='logo'>
                        <span style={{ color: '#5e4f9f' }}>cv</span>.
                        <span style={{ color: '#2488c9' }}>co</span>.
                        <span style={{ color: '#2fb4ca' }}>mz</span>
                    </div>
                    {/* <img src={loginImage} alt='' /> */}
                </div>
                <div className='login-form'>
                    <div className='form-group'>
                        <div>
                            Não tem conta?{' '}
                            <span
                                style={{ cursor: 'pointer' }}
                                className='color-primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/register');
                                }}
                            >
                                Criar conta
                            </span>
                        </div>

                        <br />

                        <GoogleLogin
                            clientId='454663731378-fl36ogmdtaiialo1nghj2vhbe155uua0.apps.googleusercontent.com'
                            buttonText='Entrar com google'
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleError}
                            cookiePolicy={'single_host_origin'}
                            className='google-login'
                            onRequest={() => {
                                setWait(true);
                            }}
                        />
                        <br />

                        <div style={{ textAlign: 'center', color: '#444' }}>
                            OU
                        </div>
                        <br />
                    </div>
                    {/* 
                    {wait && (
                        <div className='form-group'>
                            <br />
                            <label>Aguardando autenticação...</label>
                        </div>
                    )} */}

                    <>
                        <div className='form-group'>
                            <label htmlFor=''>Número de telefone (+258)</label>
                            <input
                                type='text'
                                placeholder='ex: 814355673'
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>Password</label>
                            <input
                                type='password'
                                placeholder='**************'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            <span className='error'>{error}</span>
                        </div>

                        <div className='form-group'>
                            <button
                                className='button button-success'
                                onClick={(e) => {
                                    e.preventDefault();
                                    login();
                                }}
                            >
                                Entrar
                            </button>
                        </div>
                        <br />
                        <div>
                            Esqueceu a password?{' '}
                            <span
                                style={{ cursor: 'pointer' }}
                                className='color-primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/reset-password');
                                }}
                            >
                                Recuperar
                            </span>
                        </div>
                    </>
                </div>
                <div className='login-footer'>
                    <CompanyInfo></CompanyInfo>
                </div>
            </div>
        </>
    );
};

export default Login;
