/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { IResume, ISection, ISkill } from '../../../types';

import pt from '../../../assets/img/flags/mz.svg';
import en from '../../../assets/img/flags/um.svg';
import chrome from '../../../assets/img/chrome.jpg';
import { IReducers } from '../../../store';

const QUERY = gql`
    mutation CreateResume($data: ResumeInput!) {
        createResume(data: $data) {
            ... on Resume {
                id
                firstName
                profession
                lastName
                layout
                maritalStatus
                email
                phoneNumber
                hobbies
                drivingLicense
                languages {
                    language
                    level
                }
                gender
                nationality
                birthDay
                address

                portfolio
                resumeType
                downloads
                validUntil
                paymentDate
                canEditName
                resumeLanguage

                sections {
                    header
                    elements {
                        name
                        desc
                        start
                        end
                    }
                }
                skills {
                    header
                    elements {
                        desc
                        level
                    }
                }
            }
            ... on ResumeValidationError {
                code
                message
            }
        }
    }
`;
const New: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [voucherCode, setVoucherCode] = useState('');
    const [validationError, setValidationError] = useState('');
    const [language, setLanguage] = useState('pt');
    const [isOnLimit, setIsOnLimit] = useState(false);

    const [addResume, { data, loading, error }] = useMutation(QUERY);
    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);

    const createNewResume = () => {
        const resumeData: IResume = {
            id: '',
            userId: '',
            firstName: '',
            profession: '',
            lastName: '',
            hobbies: [],
            layout: 'standard',
            aboutMe: '',
            email: '',
            portfolio: '',
            phoneNumber: [],
            address: '',
            birthDay: '',
            gender: '',
            languages: [],
            nationality: '',
            maritalStatus: '',
            drivingLicense: '',
            resumeType: 'new',
            resumeLanguage: language,
            downloads: 0,
            validUntil: new Date(),
            createdAt: new Date(),
            updatedAt: new Date(),
            canEditName: true,
            skills: [
                {
                    header: 'HABILIDADES E COMPETÊNCIAS',
                    elements: [],
                },
            ] as ISkill[],
            sections: [
                {
                    header: 'PERFIL ACADÊMICO',
                    elements: [],
                },
                {
                    header: 'EXPERIÊNCIA PROFISSIONAL',
                    elements: [],
                },
                {
                    header: 'CURSOS',
                    elements: [],
                },
                {
                    header: 'REFERÊNCIAS',
                    elements: [],
                },
            ] as ISection[],
        } as IResume;

        addResume({
            variables: {
                data: resumeData,
                voucherCode: voucherCode,
            },
        });
    };

    // const resume = useSelector(
    //     (state: IReducers) => state.newResumesReducer
    // );

    useEffect(() => {
        if (data) {
            if (data.createResume?.code) {
                console.log(data);
                setValidationError(data.createResume.message);
            } else {
                if (data?.createResume) {
                    dispatch({ type: 'SET_RESUME', resume: data.createResume });
                    navigate('/resumes/new-resume-template');
                } else {
                    setIsOnLimit(true);
                }
            }
        }
        if (error) {
            console.log(error);
        }
    }, [data, error]);

    const changeResumeLanguage = () => {
        setLanguage(language === 'pt' ? 'en' : 'pt');
    };

    useEffect(() => {
        if (language === 'en') {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'en',
            });
        } else {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'pt',
            });
        }
    }, [language]);

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Novo CV', position: 0 },
        });
    }, []);
    return (
        <>
            {loading && <Loader></Loader>}

            {isOnLimit && (
                <div className='modal-download'>
                    <div
                        className='close'
                        onClick={() => {
                            setIsOnLimit(false);
                        }}
                    >
                        fechar
                    </div>

                    <div className='download'>
                        <div
                            style={{
                                color: '#ffae21',
                                fontSize: '18px',
                                padding: '15px',
                                lineHeight: '30px',
                                backgroundColor: '#fff',
                            }}
                        >
                            Atingiu o limite(5) de CV's que pode criar.
                            <br /> Volte a lista de CV's e edite qualquer um dos
                            CV's <br />
                            <br />
                            <button
                                className='button button-primary'
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                Voltar para lista
                            </button>
                            <br />
                            <br />
                            <p style={{ fontSize: 15 }}>
                                Se tiver dúvidas contacte a nossa equipa pelo
                                Whatsapp:{' '}
                                <span style={{ color: '#000066' }}>
                                    +258 84 614 5740
                                </span>{' '}
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className='container'>
                <div
                    className='close'
                    style={{
                        position: 'fixed',
                        top: '10px',
                        right: '10px',
                        color: 'red',
                        padding: '20px',
                    }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <i className='fa fa-circle-xmark'></i>
                </div>
                <div className='tutorial'>
                    <div className='form-group'>
                        {language === 'en' ? (
                            <>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: 'orange',
                                    }}
                                >
                                    <i className='fa fa-exclamation-triangle'></i>{' '}
                                    Use Google Chrome to see and do CV download{' '}
                                    <br />
                                    <img src={chrome} style={{ width: 300 }} alt='' />
                                </h2>
                                <p>
                                    <br />
                                    Fill in the following forms at the end you
                                    will see your CV ready. <br />
                                    Usually this can only take an average of
                                    10-15 min
                                </p>
                                <p>
                                    <br />
                                    Contact the support using whatsapp for help:{' '}
                                </p>
                                <ul>
                                    <li>
                                        <i
                                            className='fa fa-message'
                                            style={{ color: '#395' }}
                                        ></i>{' '}
                                        <a
                                            style={{ color: '#395', textDecoration: 'none' }}
                                            href='https://wa.me/message/2SGBH7QH2YVUO1'
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            {' '}
                                            +258 84 614 5740{' '}
                                        </a>
                                    </li>

                                </ul>
                            </>
                        ) : (
                            <>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        color: 'orange',
                                    }}
                                >
                                    <i className='fa fa-exclamation-triangle'></i>{' '}
                                    Use o Google Chrome para poder ver e fazer
                                    download do CV <br />
                                    <img src={chrome} style={{ width: 300 }} alt='' />
                                </h2>
                                {/* <p>
                                    <br />
                                    Mais de 7000 pessoas conseguiram criar o seu
                                    CV mesmo do celular, acho que também
                                    consegues.
                                </p> */}
                                <p>
                                    <br />
                                    Contacte o suporte pelo whatsapp se tiver dificuldades é grátis:
                                </p>
                                <ul>
                                    <li>
                                        <i
                                            className='fa fa-message'
                                            style={{ color: '#395' }}
                                        ></i>{' '}
                                        <a
                                            style={{ color: '#395', textDecoration: 'none' }}
                                            href='https://wa.me/message/2SGBH7QH2YVUO1'
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            {' '}
                                            +258 84 614 5740{' '}
                                        </a>
                                    </li>
                                    {/* 
                                    <li>
                                      
                                        clique para <b>escolher a secção</b>
                                    </li> */}
                                </ul>
                            </>
                        )}
                        <div className='error'>{validationError}</div>
                    </div>

                    <div className='row' style={{ paddingBottom: '40px' }}>
                        <button
                            className='button'
                            style={{
                                maxWidth: '100%',
                                alignSelf: 'flex-start',
                            }}
                            onClick={changeResumeLanguage}
                        >
                            {language !== 'en' ? (
                                <span>
                                    Change to English{' '}
                                    <img
                                        src={en}
                                        style={{ width: '20px' }}
                                        alt=''
                                    />
                                </span>
                            ) : (
                                <span>
                                    Mudar para Português{' '}
                                    <img
                                        src={pt}
                                        style={{ width: '20px' }}
                                        alt=''
                                    />{' '}
                                </span>
                            )}
                        </button>
                    </div>

                    <div className='row'>
                        <button
                            className='button button-success'
                            onClick={(e) => {
                                e.preventDefault();
                                createNewResume();
                            }}
                        >
                            {TEXT.createCV}
                        </button>
                        <button
                            className='button button-primary'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/');
                            }}
                        >
                            {language === 'pt' ? 'Voltar' : 'Cancel'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default New;
