/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddReminder from '../../../components/AddReminder';
import { IReducers } from '../../../store';
import { IPersonalData } from '../../../types';

const PersonalInfo: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setshowModal] = useState(false);
    const [editing, setEditing] = useState(true);
    const resume = useSelector((state: IReducers) => state.newResumesReducer);
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [personalInfo, setPersonaInfo] = useState<IPersonalData>({
        userId: resume.userId,
        layout: resume.layout,
        portfolio: resume.portfolio,
        aboutMe: resume.aboutMe,
        profession: resume.profession,
        firstName: resume.firstName,
        lastName: resume.lastName,
        email: resume.email,
        phoneNumber: resume.phoneNumber,
        gender: resume.gender,
        maritalStatus: resume.maritalStatus,
        birthDay: resume.birthDay,
        nationality: resume.nationality,
        address: resume.address,
        drivingLicense: resume.drivingLicense,
    });

    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );

    const addPersonalInfo = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch({
            type: 'ADD_PERSONAL_INFO',
            personalInfo: { ...personalInfo },
        });
        setEditing(false);
    };
    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Informação pessoal', position: 4 },
        });
    }, []);
    return (
        <>
            <div className='container'>
                {resume.canEditName && showModal && (
                    <div className='modal-confirm-name'>
                        <div className='confirm-name'>
                            <div className='confirm-name-question'>
                                O Nome e o Apelido não poderão ser alterados,
                                confirme se os dados estão correctos:
                            </div>
                            <div className='name'>
                                {TEXT.personalInfo.firstName}: <span>{resume.firstName}</span>
                            </div>
                            <div className='name'>
                                {TEXT.personalInfo.lastName}: <span>{resume.lastName}</span>
                            </div>
                            <div className='row'>
                                <button
                                    className='button button-success'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/resumes/about');
                                    }}
                                >
                                    Confirmar
                                </button>
                                <button
                                    className='button button-primary'
                                    onClick={() => {
                                        setshowModal(false);
                                    }}
                                >
                                    Editar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {editing ? (
                    <form action=''>
                        <div className='form-group'>
                            <label htmlFor=''>
                                {TEXT.personalInfo.firstName}
                            </label>
                            <input
                                disabled={!resume.canEditName}
                                placeholder='Ex: Pedro'
                                value={personalInfo.firstName}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        firstName: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.lastName}</label>
                            <input
                                disabled={!resume.canEditName}
                                placeholder='Ex: Matavel'
                                value={personalInfo.lastName}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        lastName: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.email}</label>
                            <input
                                type='text'
                                value={personalInfo.email}
                                placeholder='Ex: name@example'
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        email: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>Portfolio</label>
                            <input
                                type='text'
                                value={personalInfo.portfolio}
                                placeholder='Ex: pedrosmith.com'
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        portfolio: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.profession}</label>
                            <input
                                type='text'
                                value={personalInfo.profession}
                                placeholder='Ex: Eletrecista'
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        profession: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.address}</label>
                            <input
                                type='text'
                                value={personalInfo.address}
                                placeholder='Onde você vive'
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        address: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor=''>
                                {TEXT.personalInfo.drivingLicense}
                            </label>
                            <input
                                type='text'
                                placeholder='Ex: Legeiros'
                                value={personalInfo.drivingLicense}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        drivingLicense: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.maritalStatus}</label>
                            <input
                                type='text'
                                placeholder='Ex: Casado'
                                value={personalInfo.maritalStatus}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        maritalStatus: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.gender}</label>
                            <input
                                type='text'
                                placeholder='Ex: Masculino'
                                value={personalInfo.gender}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        gender: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.nationality}</label>
                            <input
                                type='text'
                                value={personalInfo.nationality}
                                placeholder='Ex: Moçambicano'
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        nationality: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.birthDay}</label>
                            <input
                                type='text'
                                placeholder='Ex: 20-04-1997'
                                value={personalInfo.birthDay}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        birthDay: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor=''>{TEXT.personalInfo.contact}</label>
                            <input
                                type='text'
                                placeholder='Ex: 891234567, 819938541'
                                value={personalInfo.phoneNumber}
                                onChange={(e) => {
                                    setPersonaInfo({
                                        ...personalInfo,
                                        phoneNumber: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <button
                                className='button button-success'
                                onClick={addPersonalInfo}
                            >
                                {TEXT.save} <i className='fa fa-save'></i>
                            </button>
                        </div>
                        <AddReminder></AddReminder>
                    </form>
                ) : (
                    <div className='personal-info'>
                        <div className='info'>
                            <i className='icon fa fa-user'></i>
                            <div className='details'>
                                {resume.firstName} {resume.lastName}
                            </div>
                        </div>
                        {resume.email && (
                            <div className='info'>
                                <i className='icon fa fa-at'></i>
                                <div className='details'>{resume.email}</div>
                            </div>
                        )}

                        {resume.email && (
                            <div className='info'>
                                <i className='icon fa fa-globe'></i>
                                <div className='details'>
                                    {resume.portfolio}
                                </div>
                            </div>
                        )}

                        {resume.birthDay && (
                            <div className='info'>
                                <i className='icon fa fa-calendar-days'></i>
                                <div className='details'>{resume.birthDay}</div>
                            </div>
                        )}

                        {resume.maritalStatus && (
                            <div className='info'>
                                <i className='icon fa fa-ring'></i>
                                <div className='details'>
                                    {resume.maritalStatus}
                                </div>
                            </div>
                        )}

                        {resume.nationality && (
                            <div className='info'>
                                <i className='icon fa fa-flag'></i>
                                <div className='details'>
                                    {resume.nationality}
                                </div>
                            </div>
                        )}

                        {resume.profession && (
                            <div className='info'>
                                <i className='icon fa fa-tools'></i>
                                <div className='details'>
                                    {resume.profession}
                                </div>
                            </div>
                        )}

                        {resume.gender && (
                            <div className='info'>
                                <i className='icon fa fa-mars-and-venus'></i>
                                <div className='details'>{resume.gender}</div>
                            </div>
                        )}

                        {resume.phoneNumber[0] && (
                            <div className='info'>
                                <i className='icon fa fa-square-phone-flip'></i>
                                <div className='details'>
                                    {resume.phoneNumber}
                                </div>
                            </div>
                        )}

                        {resume.address && (
                            <div className='info'>
                                <i className='icon fa fa-location-dot'></i>
                                <div className='details'>{resume.address}</div>
                            </div>
                        )}
                        <div className='form-group'>
                            <button
                                className='button'
                                style={{
                                    marginTop: 20,
                                    borderColor: '#fff',
                                    color: '#fff',
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditing(true);
                                }}
                            >
                                Editar
                            </button>
                        </div>
                    </div>
                )}

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/language');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            addPersonalInfo(e);
                            if (resume.canEditName) {
                                setshowModal(true);
                            } else {
                                navigate('/resumes/about');
                            }
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalInfo;
