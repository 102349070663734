import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';
import { IElement } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import AddReminder from '../../../components/AddReminder';
import TextEditor from '../../../components/TextEditor';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const Experience: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [details, setDetails] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editor, seteditor] = useState<React.FC>();
    const [experience, setExperience] = useState<IElement>({
        id: uuidv4(),
        desc: '',
        name: '',
        start: '',
        end: '',
        details: '',
    });

    const [error, setError] = useState('');

    const experiences = useSelector(
        (state: IReducers) => state.newResumesReducer.experiences
    );
    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );
    const [showAddNewButton, setShowAddNewButton] = useState(
        experiences.length > 0
    );

    const addElement = (e: React.MouseEvent) => {
        e.preventDefault();

        if (experience.desc.trim().length > 1 && experience.name.length > 1) {
            e.preventDefault();
            if (isEditing) {
                dispatch({
                    type: 'EDIT_EXPERIENCE',
                    experience: { ...experience, details: details },
                });
            } else {
                dispatch({
                    type: 'ADD_EXPERIENCE',
                    experience: { ...experience, details: details },
                });
            }

            setExperience({
                id: uuidv4(),
                desc: '',
                name: '',
                start: '',
                end: '',
                details: '',
            });
            setDetails('');
            setShowAddNewButton(true);
            setIsEditing(false);
            setError('');
        } else {
            setError('Os dois campos acima devem ser preenchidos');
        }
    };

    const editElement = (experience: IElement) => {
        setExperience({
            id: experience.id,
            desc: experience.desc,
            name: experience.name,
            start: experience.start,
            end: experience.end,
            details: experience.details,
        });
        if (experience.details) {
            setDetails(experience.details);
        } else {
            setDetails('<p></p>');
        }
        setIsEditing(true);
        setShowAddNewButton(false);
    };
    const deleteElement = (experience: IElement) => {
        dispatch({
            type: 'DELETE_EXPERIENCE',
            experience,
        });
    };

    const getEditor = (details: string) => {
        return (
            <TextEditor
                placeholder={TEXT.experienceDetailsPlaceholder}
                setValue={setDetails}
                value={details}
            ></TextEditor>
        );
    };

    /*********Drag and drop********** */
    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;

        const items = Array.from(experiences);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: 'SET_EXPERIENCES',
            experiences: items,
        });
    }


    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.experience, position: 2 },
        });
    }, []);

    return (
        <>
            <div className='container'>
                <form action=''>
                    {isEditing && <h2 className='editing'>
                        {TEXT.editing}
                    </h2>}
                    {!isEditing &&
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <div className='school-list'  {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            experiences.map((section, index) => {

                                                return (
                                                    <Draggable key={section.id} draggableId={section.id as string} index={index}>
                                                        {(provided) => (

                                                            <div
                                                                className='school'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='school-name'>
                                                                            {section.desc}
                                                                        </div>
                                                                        <div className='grade'>
                                                                            {section.name}
                                                                        </div>
                                                                        <div className='interval'>
                                                                            <div className='begin'>
                                                                                {section.start}
                                                                            </div>{' '}
                                                                            -
                                                                            <div className='end'>
                                                                                {section.end}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col col2'>
                                                                        <span
                                                                            className='action edit'
                                                                            onClick={() => {
                                                                                editElement(section);
                                                                            }}
                                                                        >{TEXT.edit}</span>
                                                                        <span
                                                                            className='action delete'
                                                                            onClick={() => {
                                                                                deleteElement(section);
                                                                            }}
                                                                        >{TEXT.delete}</span>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </Draggable>
                                                );
                                            })}
                                        {provided.placeholder}

                                    </div>)}
                            </Droppable>
                        </DragDropContext>}
                    {showAddNewButton ? (
                        <div className='form-group'>
                            <button
                                className='button button-primary add-new'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddNewButton(false);
                                }}
                            >
                                {TEXT.addNew} <i className='fa fa-circle-plus'></i>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='form-group'>
                                <label htmlFor=''>
                                    {TEXT.experienceLocation}

                                </label>
                                <input
                                    type='text'
                                    placeholder={TEXT.experienceLocationPlaceholder}

                                    value={experience.desc}
                                    onChange={(e) => {
                                        setExperience({
                                            ...experience,
                                            desc: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor=''>
                                    {TEXT.experienceName}
                                </label>
                                <input
                                    type='text'
                                    placeholder={TEXT.experiencePlaceholder}

                                    value={experience.name}
                                    onChange={(e) => {
                                        setExperience({
                                            ...experience,
                                            name: e.target.value,
                                        });
                                    }}
                                />
                                <span className='error'>{error}</span>
                            </div>
                            <div className='row'>
                                <div className='form-group'>
                                    <label htmlFor=''>
                                        {TEXT.start}
                                    </label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.startPlaceholder}

                                        value={experience.start}
                                        onChange={(e) => {
                                            setExperience({
                                                ...experience,
                                                start: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor=''>
                                        {TEXT.end}
                                    </label>
                                    <input
                                        type='text'
                                        placeholder={TEXT.endPlaceholder}

                                        value={experience.end}
                                        onChange={(e) => {
                                            setExperience({
                                                ...experience,
                                                end: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor=''>
                                    {TEXT.experienceDetails}

                                </label>
                                {details ? getEditor(details) : getEditor('')}
                            </div>

                            <div className='form-group'>
                                <button
                                    className='button button-success'
                                    onClick={addElement}
                                >
                                    Salvar <i className='fa fa-save'></i>
                                </button>
                            </div>
                        </>
                    )}
                </form>
                <AddReminder></AddReminder>

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/course');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div
                        className='next'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/language');
                        }}
                    >
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Experience;
