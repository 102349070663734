/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { IReducers } from '../../../store';
import { IResume } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { getAtualResume } from '../../../utils';
import { updateResumeMutation } from '../../../graphql/mutations';
import pt from '../../../assets/img/flags/mz.svg';
import en from '../../../assets/img/flags/um.svg';
import chrome from '../../../assets/img/chrome.jpg';

import ejs from '../../../utils/ejs';
import { fileEjs } from '../../../utils/file';

import axios from 'axios';

const SKILL_LEVELS = [
    'Iniciante',
    'Intermediário',
    'Bom',
    'Muito Bom',
    'Excelente',
];
const LANGUAGE_LEVELS = [
    'Iniciante',
    'Intermediário',
    'Avançado',
    'Fluente',
    'Nativo',
];
const DEFAULT_TEMPLATE = 'professional';
const Review: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isResumeEmpty, setIsResumeEmpty] = useState(false);
    const API_URL = useSelector(
        (state: IReducers) => state.utilReducer.resumePdfUrl
    );

    const RESUME_PHOTO_URL = useSelector(
        (state: IReducers) => state.utilReducer.resumePhotoUrl
    );

    const [sectionNumber, setSectionNumber] = useState(0);
    const newResume = useSelector(
        (state: IReducers) => state.newResumesReducer
    );
    const TOKEN = useSelector(
        (state: IReducers) => state.authReducer.user?.token
    );
    const TEMPLATES = useSelector(
        (state: IReducers) => state.utilReducer.templates
    );

    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);

    const [loading, setLoading] = useState(false);
    const [iframeURL, setIframeURL] = useState('');
    const [downloadURL, setDownloadURL] = useState('');
    const [message, setMessage] = useState('Aguarde, Gerando o CV...');

    const gotToEmptySection = () => {
        // If the resume has no data return
        if (newResume.educations.length === 0) {
            navigate('/resumes/education');
        } else if (newResume.firstName.length === 0) {
            navigate('/resumes/personal-info');
        } else if (newResume.languages.length === 0) {
            navigate('/resumes/language');
        }
    };
    const saveAndDownload = (e: React.MouseEvent) => {
        // If the resume has no data return
        if (
            newResume.educations.length === 0 ||
            newResume.firstName.length === 0 ||
            newResume.languages.length === 0
        ) {
            setIsResumeEmpty(true);
            return;
        }

        const resumeData: IResume = getAtualResume(newResume);

        const validUntil = new Date(
            parseInt(newResume.validUntil.toString())
        ).getTime();

        if (newResume.resumeType === 'new') {
            navigate('/resumes/payment');
        } else if (validUntil < Date.now()) {
            navigate('/resumes/payment');
        } else {
            setLoading(true);
            axios
                .get(`${API_URL}/get-resume-pdf/${newResume.id}`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Basic ${TOKEN}`,
                    },
                })
                .then((res) => {
                    const url = URL.createObjectURL(
                        new Blob([res.data], { type: 'application/pdf' })
                    );
                    setDownloadURL(url);
                })
                .catch((error) => {
                    if (error.response.status === 412) {
                        setMessage(
                            'Validade do pagamento expirou, contacte: +258  86 435 2310 para renovar'
                        );
                    } else {
                        setMessage('Erro ao gerar o CV, contacte o suporte');
                    }
                    console.error(error);
                });
        }
    };

    const canDownload = () => {
        const resumeData: IResume = getAtualResume(newResume);

        const validUntil = new Date(
            parseInt(newResume.validUntil.toString())
        ).getTime();

        if (newResume.resumeType === 'new') {
            return false;
        } else if (validUntil < Date.now()) {
            return false;
        }
        return true;
    };
    const disableEditName = () => {
        const resumeData = newResume;
        if (resumeData.canEditName) {
            if (resumeData.firstName !== '' || resumeData.lastName !== '') {
                dispatch({ type: 'DISABLE_EDIT_NAME' });
            }
        }
    };

    const changeSection = (sectionNumberParam: number) => {
        if (sectionNumberParam === sectionNumber) {
            setSectionNumber(0);
        } else {
            setSectionNumber(sectionNumberParam);
        }
    };

    const createIframe = () => {
        const photoUrl = `${RESUME_PHOTO_URL}${newResume.id}.jpg`;
        URL.revokeObjectURL(iframeURL);

        let template = DEFAULT_TEMPLATE;
        const templateNames = Object.keys(TEMPLATES);
        for (let i = 0; i < templateNames.length; i++) {
            if (templateNames[i] === newResume.layout) {
                template = templateNames[i];
            }
        }
        const html = ejs.render(TEMPLATES[template], {
            resume: getAtualResume(newResume),
            previewCondition: true,
            // photoUrl: newResume.aboutPhoto,
            photoUrl,
        });
        const url = URL.createObjectURL(
            new Blob([html], { type: 'text/html' })
        );
        setIframeURL(url);
    };
    const changeResumeLanguage = () => {
        if (newResume.resumeLanguage === 'en') {
            dispatch({ type: 'SET_RESUME_LANGUAGE', resumeLanguage: 'pt' });
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'pt',
            });
            dispatch({
                type: 'SET_RESUME_HEADER',
                resumesHeader: { desc: 'Revião', position: 0 },
            });
        } else {
            dispatch({ type: 'SET_RESUME_LANGUAGE', resumeLanguage: 'en' });
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'en',
            });
            dispatch({
                type: 'SET_RESUME_HEADER',
                resumesHeader: { desc: 'Review', position: 0 },
            });
        }
    };

    useEffect(() => {
        createIframe();
    }, [newResume]);

    useEffect(() => {
        // Set Resumne edit Language
        if (newResume.resumeLanguage === 'pt') {
            dispatch({ type: 'SET_RESUME_LANGUAGE', resumeLanguage: 'pt' });
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'pt',
            });
            dispatch({
                type: 'SET_RESUME_HEADER',
                resumesHeader: { desc: 'Revião', position: 0 },
            });
        } else {
            dispatch({ type: 'SET_RESUME_LANGUAGE', resumeLanguage: 'en' });
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'en',
            });
            dispatch({
                type: 'SET_RESUME_HEADER',
                resumesHeader: { desc: 'Review', position: 0 },
            });
        }

        return () => {
            URL.revokeObjectURL(iframeURL);
        };
    }, []);

    return (
        <>
            {isResumeEmpty && (
                <div className='modal-download'>
                    <div
                        className='close'
                        onClick={() => {
                            setIsResumeEmpty(false);
                        }}
                    >
                        fechar
                    </div>

                    <div className='download'>
                        <div
                            style={{
                                color: '#2e313f',
                                fontSize: '20px',
                                padding: '15px',
                                lineHeight: '30px',
                                backgroundColor: '#fff',
                            }}
                        >
                            Para fazer o download deve preecher{' '}
                            <span style={{ fontWeight: 'bold' }}></span> as
                            secções:
                            {newResume.educations.length === 0 && (
                                <p>
                                    <span style={{ color: '#ffae21' }}>⚠ </span>
                                    Perfil Acadêmico
                                </p>
                            )}
                            {newResume.languages.length === 0 && (
                                <p>
                                    <span style={{ color: '#ffae21' }}>⚠ </span>{' '}
                                    Línguas
                                </p>
                            )}
                            {newResume.firstName.length === 0 && (
                                <p>
                                    <span style={{ color: '#ffae21' }}>⚠ </span>{' '}
                                    Informação pessoal
                                </p>
                            )}
                            <br />
                            <br /> Preecher agora?
                            <br />
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <button
                                    className='button button-success'
                                    onClick={() => {
                                        gotToEmptySection();
                                    }}
                                >
                                    Sim
                                </button>

                                <button
                                    className='button button-primary'
                                    onClick={() => {
                                        setIsResumeEmpty(false);
                                    }}
                                >
                                    Não
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(downloadURL || loading) && (
                <div className='modal-download'>
                    <div
                        className='close'
                        onClick={() => {
                            setDownloadURL('');
                            setLoading(false);
                        }}
                    >
                        fechar
                    </div>

                    <div className='download'>
                        {downloadURL ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <a
                                        rel='noopener noreferrer'
                                        onClick={disableEditName}
                                        href={downloadURL}
                                        // download='cv.co.mz.pdf'
                                        target='_blank'
                                    >
                                        <button className='button button-primary'>
                                            Abrir PDF
                                        </button>
                                    </a>{' '}
                                    <a
                                        rel='noopener noreferrer'
                                        onClick={disableEditName}
                                        href={downloadURL}
                                        download={`${newResume.firstName}-${newResume.lastName}-cv.co.mz.pdf`}
                                    >
                                        <button
                                            className={'button button-success'}
                                        >
                                            Download
                                        </button>
                                    </a>
                                </div>
                                <br />
                                <p
                                    style={{
                                        fontSize: '14px',
                                        color: 'orange',
                                        marginTop: '40px',
                                        width: 'auto',
                                        maxWidth: '220px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <i className='fa fa-exclamation-triangle'></i>{' '}
                                    Use o navegador Google Chrome se não
                                    conseguir fazer download ou abrir o CV
                                    <br />
                                    <img
                                        src={chrome}
                                        style={{ width: 180 }}
                                        alt=''
                                    />
                                </p>
                            </>
                        ) : (
                            <div
                                style={{
                                    color: '#444',
                                    fontSize: '24px',
                                }}
                            >
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className='container'>
                <div className='review'>
                    <div className='cv-preview-wrapper'>
                        <div className='preview-loader'>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div className='loader'></div>
                                <p
                                    style={{
                                        fontSize: '14px',
                                        color: 'orange',
                                        // marginTop: '40px',
                                        width: 'auto',
                                        maxWidth: '200px',
                                    }}
                                >
                                    <i className='fa fa-exclamation-triangle'></i>{' '}
                                    Use o navegador Google Chrome
                                    <br />
                                    <img
                                        src={chrome}
                                        style={{ width: '220px' }}
                                        alt=''
                                    />
                                </p>
                            </div>
                        </div>
                        <iframe
                            className='cv-preview'
                            title='CV'
                            src={iframeURL}
                            width='794'
                            height='1122'
                        ></iframe>
                    </div>

                    <div className='form-group  change-template-button'>
                        <div className='row'>
                            <div
                                className='button  button-primary change-template-button'
                                onClick={() => {
                                    navigate('/resumes/template');
                                }}
                            >
                                {TEXT.template}
                            </div>
                            <div
                                className='button'
                                onClick={() => {
                                    changeResumeLanguage();
                                }}
                            >
                                {newResume.resumeLanguage === 'pt' ? (
                                    <span>
                                        Change to English{' '}
                                        <img
                                            src={en}
                                            style={{ width: '16px' }}
                                            alt=''
                                        />
                                    </span>
                                ) : (
                                    <span>
                                        Mudar para Português{' '}
                                        <img
                                            src={pt}
                                            style={{ width: '16px' }}
                                            alt=''
                                        />{' '}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='review-actions'>
                <button
                    onClick={saveAndDownload}
                    className={`generate ${
                        canDownload() ? '' : 'generate-disabled'
                    }`}
                >
                    {TEXT.download}
                </button>
                <button
                    className='change-template-footer'
                    onClick={() => {
                        navigate('/resumes/template');
                    }}
                >
                    {TEXT.template}
                </button>
                <button
                    onClick={changeResumeLanguage}
                    className='change-language-footer'
                >
                    {newResume.resumeLanguage === 'pt' ? (
                        <span> Change to English</span>
                    ) : (
                        <span> Mudar para Português</span>
                    )}
                </button>
                <button
                    onClick={(e) => {
                        dispatch({ type: 'SHOW_MENU', showMenu: true });
                    }}
                    className='edit'
                >
                    {TEXT.edit}
                </button>
            </div>
        </>
    );
};

export default Review;
