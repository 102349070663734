import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../store';

const Menu: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToMenu = (menu: string) => {
        navigate(menu);
        dispatch({ type: 'SHOW_MENU', showMenu: false });
    };
    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );
    return (
        <>
            <div className='menu'>
                <div
                    className='close'
                    onClick={() => {
                        dispatch({ type: 'SHOW_MENU', showMenu: false });
                    }}
                >
                    {TEXT.close}
                </div>
                <div className='container'>
                    <div className='menu-list'>
                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/education');
                            }}
                        >
                            {TEXT.menu.education}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/course');
                            }}
                        >
                            {TEXT.menu.course}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/experience');
                            }}
                        >
                            {TEXT.menu.experience}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/language');
                            }}
                        >
                            {TEXT.menu.language}

                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/personal-info');
                            }}
                        >
                            {TEXT.menu.personalInfo}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/about');
                            }}
                        >
                            {TEXT.menu.aboutMe}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/skill');
                            }}
                        >
                            {TEXT.menu.skills}
                        </div>

                        <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/reference');
                            }}
                        >
                            {TEXT.menu.references}
                        </div>

                        {/* <div
                            className='menu-item'
                            onClick={() => {
                                goToMenu('/resumes/review');
                            }}
                        >
                            Revisão
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu;
