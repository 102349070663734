/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReducers } from '../../../store';
import mpesaLogo from '../../../assets/img/m-pesa-logo-2d.png';
import { MPESA_PAYMENT_QUERY as QUERY } from '../../../graphql/queries';
import LoaderMPesa from '../../../components/LoaderMPesa';

const MPesa: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validationError, setValidationError] = useState('');
    const [payResumeByVoucher, { data, loading, error, reset }] =
        useMutation(QUERY);

    const resume = useSelector((state: IReducers) => state.newResumesReducer);
    const resumeId = resume.id;

    const [isExpired, setIsExperid] = useState(resume.resumeType !== 'new');

    const validPhoneNumber = (phoneNumber: string) => {
        return /^8[45][0-9]{7}$/.test(phoneNumber);
    };

    const payMethod = (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
            case 'e-mola':
                navigate('/resumes/payment/emola')
                break;
            case 'm-pesa':
                navigate('/resumes/payment/mpesa')
                break
            default:
                break;
        }

    }

    useEffect(() => {
        if (data) {
            if (data.payResumeMPesa?.code) {
                // console.log(data);
                if (data.payResumeMPesa?.code === 402) {
                    setValidationError('PIN incorreto');
                } else {
                    setValidationError('Falha no procesamento');
                }
            } else {
                dispatch({ type: 'SET_RESUME', resume: data.payResumeMPesa });
                navigate('/resumes/review');
            }
        }
        if (error) {
            setValidationError('Falha na coneção verfique a internet');
            reset();
            console.log(error);
        }
    }, [data, error]);

    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: 'Pagamento', position: 0 },
        });
    }, []);
    return (
        <>
            {loading && (
                <LoaderMPesa>
                    Confirme com o{' '}
                    <span style={{ fontWeight: 'bold' }}>PIN M-Pesa</span> o
                    pagamento no telefone com o número{' '}
                    <span style={{ fontWeight: 'bold' }}>{phoneNumber}</span>
                    <br />
                    <br /> Pode levar alguns segundos, fique atento...
                </LoaderMPesa>
            )}
            <div className='container'>
                <div
                    className='over-close'
                    onClick={() => {
                        navigate('/resumes/review');
                    }}
                ></div>

                <div className="form-group">
                    <label htmlFor="">Escolha o método de pagamento</label>
                    <select className="form-group" name="pay-method" onChange={payMethod}>
                        <option value="m-pesa">m-pesa</option>
                        <option value="e-mola">e-mola</option>

                    </select>
                </div>
                <div className='choose-language'>
                    <div className='form-group'>
                        <label htmlFor='voucher-code'>
                            Introduza o teu número M-PESA
                        </label>
                        <br />
                        <input
                            type='number'
                            // placeholder='EX:  846145740'
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                        />
                        <div className='error'>{validationError}</div>
                    </div>

                    <div className='form-group'>
                        <span>
                            {' '}
                            Ao continuar estará concordando com os{' '}
                            <a
                                target={'_blank'}
                                href='https://cv.co.mz/terms.html'
                                rel='noreferrer'
                            >
                                {' '}
                                Termos e condições
                            </a>
                        </span>
                        <br />
                        <button
                            className='button button-primary'
                            onClick={async (e) => {
                                e.preventDefault();
                                if (validPhoneNumber(phoneNumber)) {
                                    setValidationError('');
                                    payResumeByVoucher({
                                        variables: { resumeId, phoneNumber },
                                    });
                                } else {
                                    setValidationError(
                                        'Número m-pesa inválido'
                                    );
                                }
                            }}
                        >
                            Continuar
                        </button>
                    </div>
                    <br />
                    <div className='form-group'>
                        <img
                            style={{ maxWidth: '200px' }}
                            src={mpesaLogo}
                            alt=''
                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default MPesa;
