import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { IReducers } from '../store';
import { IUser } from '../types';

// import { Container } from './styles';

const CheckToken: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: IReducers) => state.authReducer.user);

    const checkToken = () => {
        const userTokenString = localStorage.getItem('token');
        if (userTokenString) {
            const storedUser: IUser = JSON.parse(userTokenString);
            const { exp }: { exp: number } = jwtDecode(storedUser.token + '');
            const loginUrl = window.location.origin;

            if (Date.now() >= exp * 1000) {
                console.error('Token Expired');
            } else {
                dispatch({ type: 'SET_USER', user: storedUser });
            }
        }
    };

    useEffect(() => {
        const url = window.location.pathname;
        switch (url) {
            case '/':
                break;
            case '/register':
                break;
            default:
                navigate('/');
                break;
        }
    }, []);

    return (
        <>
            <Outlet></Outlet>
        </>
    );
};

export default CheckToken;
