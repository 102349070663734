import { gql } from '@apollo/client';

export const getUsers = gql`
    query {
        users {
            id
            firstName
            lastName
            email
        }
    }
`;

export const getProfile = gql`
    query {
        profile {
            id
            firstName
            lastName
            email
            phoneNumber
            loginType
            recommendationStats {
                credit
                recommendationId
                isRecommendationSet
                recommendationQuant
                recommendationDate
            }
            createdAt
        }
    }
`;

export const getResumesById = gql`
    query ($id: ID!) {
        resumesByUserId(id: $id) {
            id
            firstName
            profession
            lastName
            layout
            email
            phoneNumber
            gender
            aboutMe
            hobbies
            nationality
            maritalStatus
            birthDay
            address
            drivingLicense

            resumeLanguage
            portfolio
            resumeType
            downloads
            validUntil
            canEditName
            paymentDate
            createdAt
            updatedAt
            sections {
                header
                elements {
                    id
                    name
                    desc
                    start
                    end
                    details
                }
            }
            skills {
                header
                elements {
                    id
                    desc
                    level
                }
            }

            languages {
                id
                language
                level
            }
        }
    }
`;

export const getUserResumes = gql`
    query {
        resumes {
            id
            firstName
            profession
            lastName
            layout
            email
            phoneNumber
            gender
            aboutMe
            hobbies
            nationality
            maritalStatus
            birthDay
            address
            drivingLicense

            resumeLanguage
            portfolio
            resumeType
            downloads
            validUntil
            paymentDate
            canEditName
            createdAt
            updatedAt
            sections {
                header
                elements {
                    id
                    name
                    desc
                    start
                    end
                    details
                }
            }
            skills {
                header
                elements {
                    id
                    desc
                    level
                }
            }

            languages {
                id
                language
                level
            }
        }
    }
`;

export const VOUCHER_PAYMENT_QUERY = gql`
    mutation PayResumeVoucher($resumeId: ID!, $voucherCode: String!) {
        payResumeVoucher(voucherCode: $voucherCode, resumeId: $resumeId) {
            ... on Resume {
                id
                firstName
                profession
                lastName
                layout
                maritalStatus
                email
                phoneNumber
                hobbies
                drivingLicense
                languages {
                    language
                    level
                }
                gender
                nationality
                birthDay
                address
                portfolio

                resumeType
                downloads
                validUntil
                paymentDate
                canEditName
                createdAt
                updatedAt
                sections {
                    header
                    elements {
                        id
                        name
                        desc
                        details
                        start
                        end
                    }
                }
                skills {
                    header
                    elements {
                        desc
                        level
                    }
                }
            }
            ... on ResumeValidationError {
                code
                message
            }
        }
    }
`;

export const ADMIN_PAYMENT_QUERY = gql`
    mutation PayResumeVoucher($resumeId: ID!, $voucherCode: String!) {
        payResumeAdmin(voucherCode: $voucherCode, resumeId: $resumeId) {
            ... on Resume {
                id
                firstName
                profession
                lastName
                layout
                maritalStatus
                email
                phoneNumber
                hobbies
                drivingLicense
                languages {
                    language
                    level
                }
                gender
                nationality
                birthDay
                address
                portfolio

                resumeType
                downloads
                validUntil
                paymentDate
                canEditName
                createdAt
                updatedAt
                sections {
                    header
                    elements {
                        id
                        name
                        desc
                        details
                        start
                        end
                    }
                }
                skills {
                    header
                    elements {
                        desc
                        level
                    }
                }
            }
            ... on ResumeValidationError {
                code
                message
            }
        }
    }
`;
export const MPESA_PAYMENT_QUERY = gql`
    mutation PayResumeMPesa($resumeId: ID!, $phoneNumber: String!) {
        payResumeMPesa(phoneNumber: $phoneNumber, resumeId: $resumeId) {
            ... on Resume {
                id
                firstName
                profession
                lastName
                layout
                maritalStatus
                email
                phoneNumber
                hobbies
                drivingLicense
                languages {
                    language
                    level
                }
                gender
                nationality
                birthDay
                address
                portfolio

                resumeType
                downloads
                validUntil
                paymentDate
                canEditName
                createdAt
                updatedAt
                sections {
                    header
                    elements {
                        id
                        name
                        desc
                        details
                        start
                        end
                    }
                }
                skills {
                    header
                    elements {
                        desc
                        level
                    }
                }
            }
            ... on ResumeValidationError {
                code
                message
            }
        }
    }
`;

export const FREE_PAYMENT_QUERY = gql`
    mutation PayResumeMPesa($resumeId: ID!) {
        payResumeFree(resumeId: $resumeId) {
            ... on Resume {
                id
                firstName
                profession
                lastName
                layout
                maritalStatus
                email
                phoneNumber
                hobbies
                drivingLicense
                languages {
                    language
                    level
                }
                gender
                nationality
                birthDay
                address
                portfolio

                resumeType
                downloads
                validUntil
                paymentDate
                canEditName
                createdAt
                updatedAt
                sections {
                    header
                    elements {
                        id
                        name
                        desc
                        details
                        start
                        end
                    }
                }
                skills {
                    header
                    elements {
                        desc
                        level
                    }
                }
            }
            ... on ResumeValidationError {
                code
                message
            }
        }
    }
`;
