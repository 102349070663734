import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { IResume } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../store';
const QUERY = gql`
    query {
        resumes {
            id
            firstName
            profession
            lastName
            layout
            email
            phoneNumber
            gender
            nationality
            birthDay
            address
            paymentDate
            canEditName

            sections {
                header
                elements {
                    id
                    name
                    desc
                    start
                    end
                }
            }
            skills {
                header
                elements {
                    desc
                    level
                }
            }
        }
    }
`;
const ResumesList: React.FC = () => {
    const dispatch = useDispatch();

    const { data, loading, error } = useQuery(QUERY);
    const resumes = useSelector(
        (state: IReducers) => state.resumesReducer.resumes
    );

    useEffect(() => {
        if (data?.resumes) {
            dispatch({ type: 'SET_RESUMES', resumes: data.resumes });
        }
    }, [data]);

    return (
        <>
            <div className='container'>
                <h1>Resumes List</h1>
                <form action=''>
                    <div className='form-group'>
                        <label htmlFor=''>Nome</label>
                        <input type='text' />
                    </div>
                </form>

                <div>
                    {resumes.map((resume: IResume) => {
                        return (
                            <div key={resume.id}>
                                <span>{resume.firstName}</span>
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href={`http://localhost:3001/pdf/${resume.id}`}
                                >
                                    {resume.id}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ResumesList;
