import React from 'react';

// import { Container } from './styles';
type Props = {
};
const LoaderMPesa: React.FC<Props> = ({ children }) => {
    return (
        <>
            <div className='loader-conteiner'>

                <span className='message'>
                    <span className='loader'></span>
                    <br />
                    <div>{children}
                    </div>
                </span>
            </div>
        </>
    );
};

export default LoaderMPesa;
