/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { IResume, ISection, ISkill, IUser } from '../../../types';

import pt from '../../../assets/img/flags/mz.svg';
import en from '../../../assets/img/flags/um.svg';
import chrome from '../../../assets/img/chrome.jpg';
import { IReducers } from '../../../store';
import { getProfile } from '../../../graphql/queries';

const QUERY = getProfile;

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUser>();
    const [validationError, setValidationError] = useState('');
    const [language, setLanguage] = useState('pt');
    const [isOnLimit, setIsOnLimit] = useState(false);

    const [getProfile, { data, loading, error }] = useLazyQuery(QUERY);
    const TEXT = useSelector((state: IReducers) => state.utilReducer.text);
    // const user = useSelector((state: IReducers) => state.authReducer.user);

    // const resume = useSelector(
    //     (state: IReducers) => state.newResumesReducer
    // );

    useEffect(() => {
        if (data) {
            console.log(data.profile);
            setUser(data.profile);
        }

        if (error) {
            console.log(error);
        }
    }, [data, error]);

    const changeResumeLanguage = () => {
        setLanguage(language === 'pt' ? 'en' : 'pt');
    };

    useEffect(() => {
        if (language === 'en') {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'en',
            });
        } else {
            dispatch({
                type: 'SET_RESUME_EDIT_LANGUAGE',
                resumeLanguage: 'pt',
            });
        }
    }, [language]);

    useEffect(() => {
        // dispatch({
        //     type: 'SET_RESUME_HEADER',
        //     resumesHeader: { desc: 'Novo CV', position: 0 },
        // });
        getProfile();
    }, []);
    return (
        <>
            {loading && <Loader></Loader>}

            <div className='container'>
                <div
                    className='close'
                    style={{
                        position: 'fixed',
                        top: '10px',
                        right: '10px',
                        color: 'red',
                        padding: '20px',
                    }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <i className='fa fa-circle-xmark'></i>
                </div>
                <div className='tutorial'>
                    <div className='form-group'>
                        <>
                            <h2
                                style={{
                                    fontSize: '24px',
                                    color: 'blue',
                                }}
                            >
                                {/* <i className='fa fa-info'></i>{' '} */}
                                {`${user?.firstName} ${user?.lastName}`} <br />
                            </h2>
                            <ul>
                                <li>
                                    <span className='bold'>Teu ID:</span>{' '}
                                    <span className='bold font-success'>
                                        {' '}
                                        {user?.loginType === 'email'
                                            ? user?.email?.substring(
                                                  0,
                                                  user?.email.indexOf('@')
                                              )
                                            : user?.phoneNumber}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {user?.loginType === 'email'
                                            ? 'Gmail'
                                            : 'Celular'}
                                        :
                                    </span>{' '}
                                    {user?.loginType === 'email'
                                        ? user?.email
                                        : user?.phoneNumber}
                                </li>
                                <li>
                                    <span>Crédito:</span>{' '}
                                    {user?.recommendationStats?.credit}
                                </li>
                                <li>
                                    <span>Recomendações:</span>{' '}
                                    {
                                        user?.recommendationStats
                                            ?.recommendationQuant
                                    }
                                </li>
                                <li>
                                    <span>Tipo de perfil:</span>{' '}
                                    {user?.loginType}
                                </li>
                            </ul>
                            <p>
                                <br />
                                Contacte o suporte pelo whatsapp :
                            </p>
                            <ul>
                                <li>
                                    <i
                                        className='fa fa-message'
                                        style={{ color: '#395' }}
                                    ></i>{' '}
                                    <a
                                        style={{
                                            color: '#395',
                                            textDecoration: 'none',
                                        }}
                                        href='#'
                                        rel='noreferrer'
                                    >
                                        {' '}
                                        +258 86 435 2310{' '}
                                    </a>
                                </li>
                                {/* 
                                    <li>
                                      
                                        clique para <b>escolher a secção</b>
                                    </li> */}
                            </ul>
                        </>

                        <div className='error'>{validationError}</div>
                    </div>

                    <div className='row'>
                        {/* <button
                            className='button button-success'
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            Atualizar
                        </button> */}
                        <button
                            className='button button-primary'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/');
                            }}
                        >
                            {language === 'pt' ? 'Voltar' : 'Cancel'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
