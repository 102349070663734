import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddReminder from '../../../components/AddReminder';
import { updateResumeMutation } from '../../../graphql/mutations';

import { IReducers } from '../../../store';
import { IResume, ISkill, ISkillElement } from '../../../types';
import { getAtualResume } from '../../../utils';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const QUERY = updateResumeMutation;

const SKILL_LEVELS = [
    'Iniciante',
    'Intermediário',
    'Bom',
    'Muito Bom',
    'Excelente',
];

const MAX_CHARACTERS = 64;

const Skill: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [skillDesc, setSkillDesc] = useState<string>('');
    const [skillDescError, setSkillDescError] = useState<string>('');
    const [editedSkill, seteditedSkill] = useState<ISkillElement>()
    const [skillLevel, setSkillLevel] = useState<string>('2');
    const [isEditing, setIsEditing] = useState(false);

    const skills = useSelector(
        (state: IReducers) => state.newResumesReducer.skills
    );

    const TEXT = useSelector(
        (state: IReducers) => state.utilReducer.text
    );
    const [showAddNewButton, setShowAddNewButton] = useState(skills.length > 0);

    const changeSkillLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSkillLevel(e.target.value);
    };



    const addSkill = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isEditing) {

            if (skillDesc.trim().length > 1 && skillDesc.trim().length < MAX_CHARACTERS) {
                setShowAddNewButton(true);
                dispatch({
                    type: 'EDIT_SKILL',
                    skill: {
                        desc: skillDesc,
                        level: parseInt(skillLevel),
                        id: editedSkill?.desc,
                    } as ISkillElement,
                });
                setSkillLevel('2');
                setSkillDesc('');
                setSkillDescError('');
                setIsEditing(false)
            } else {
                setSkillDescError(`Deve ter no mínimo 2 caracteres e maximo ${MAX_CHARACTERS}`);
            }
        } else {
            if (skillDesc.trim().length > 1 && skillDesc.trim().length < MAX_CHARACTERS) {
                setShowAddNewButton(true);
                dispatch({
                    type: 'ADD_SKILL',
                    skill: {
                        desc: skillDesc,
                        level: parseInt(skillLevel),
                        id: uuidv4(),
                    } as ISkillElement,
                });
                setSkillLevel('2');
                setSkillDesc('');
                setSkillDescError('');
            } else {
                setSkillDescError(`Deve ter no mínimo 2 caracteres e maximo ${MAX_CHARACTERS}`);
            }
        }
    };

    const editSkill = (skill: ISkillElement) => {
        seteditedSkill(skill)
        setSkillDesc(skill.desc);
        setSkillLevel(skill.level + '')
        setIsEditing(true);
        setShowAddNewButton(false);
    }
    const deleteSkill = (skill: ISkillElement) => {
        dispatch({ type: 'DELETE_SKILL', skill });
    };

    /*********Drag and drop********** */
    function handleOnDragEnd(result: any) {
        if (!result.destination) return;

        const items = Array.from(skills);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({
            type: 'SET_SKILLS',
            skills: items,
        });
    }
    useEffect(() => {
        dispatch({
            type: 'SET_RESUME_HEADER',
            resumesHeader: { desc: TEXT.menu.skills, position: 5 },
        });
    }, []);
    return (
        <>
            <div className='container'>
                <form action=''>
                    {isEditing && <h2 className='editing'>
                        {TEXT.editing}
                    </h2>}
                    {!isEditing &&
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (

                                    <div className='skill-list'   {...provided.droppableProps} ref={provided.innerRef}>
                                        {skills.map((skill, index) => {
                                            return (
                                                <Draggable key={skill.desc} draggableId={skill.desc} index={index}>
                                                    {(provided) => (

                                                        <div
                                                            className='skill'
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        >

                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className='desc'>
                                                                        {skill.desc}
                                                                    </div>
                                                                    <div className='level'>
                                                                        {SKILL_LEVELS[skill.level]}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='col col2'

                                                                >

                                                                    <span
                                                                        className='action edit'
                                                                        onClick={() => {
                                                                            editSkill(skill);
                                                                        }}
                                                                    >{TEXT.edit}</span>
                                                                    <span
                                                                        className='action delete'
                                                                        onClick={() => {
                                                                            deleteSkill(skill);
                                                                        }}
                                                                    >{TEXT.delete}</span>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}

                                    </div>)}
                            </Droppable>
                        </DragDropContext>}
                    {showAddNewButton ? (
                        <div className='form-group'>
                            <button
                                className='button button-primary add-new'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddNewButton(false);
                                }}
                            >
                                {TEXT.addNew}  <i className='fa fa-circle-plus'></i>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className='form-group'>
                                <label htmlFor=''>
                                    {TEXT.skill}
                                </label>
                                <input
                                    type='text'
                                    placeholder={TEXT.skillPlaceholder}

                                    value={skillDesc}
                                    onChange={(e) => {

                                        setSkillDesc(e.target.value);
                                    }}
                                />
                                <span className='error'>{skillDescError}</span>
                            </div>

                            <div className='form-group'>
                                {/* <label htmlFor=''>{TEXT.skillLevel}</label> */}
                                <div className='form-group-range'>
                                    <input
                                        type='range'
                                        min={'0'}
                                        max={(
                                            SKILL_LEVELS.length - 1
                                        ).toString()}
                                        value={parseInt(skillLevel)}
                                        className='slider'
                                        id='myRange'
                                        onChange={(e) => {
                                            setSkillLevel(e.target.value);
                                        }}
                                    />
                                    <label>
                                        {SKILL_LEVELS[parseInt(skillLevel)]}{' '}
                                    </label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <button
                                    className='button button-success'
                                    onClick={addSkill}
                                >
                                    {TEXT.save}
                                </button>
                            </div>
                        </>
                    )}
                </form>
                <AddReminder></AddReminder>
                <div className='next-step'>
                    <i
                        className='icon fa fa-circle-left'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/about');
                        }}
                    >
                        {' '}
                    </i>
                    <i
                        className='icon fa fa-circle-right'
                        onClick={() => {
                            navigate('/resumes/refrence')
                        }}                    >
                        {' '}
                    </i>
                </div>

                <div className='next-step'>
                    <div
                        className='preview'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/resumes/about');
                        }}
                    >
                        <i className='fa fa-circle-left'></i> {TEXT.previous}
                    </div>
                    <div className='next' onClick={() => {
                        navigate('/resumes/reference')
                    }}>
                        {TEXT.next} <i className='fa fa-circle-right'></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Skill;
