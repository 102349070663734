import { INewResumeState } from '../store/newResumeReducer';
import { IResume, ISection, ISkill } from '../types';

export function getAtualResume(newResume: INewResumeState) {
    const resumeData: IResume = {
        id: newResume.id,
        userId: newResume.userId,
        firstName: newResume.firstName,
        profession: newResume.profession,
        aboutMe: newResume.aboutMe,
        lastName: newResume.lastName,
        layout: newResume.layout,
        portfolio: newResume.portfolio,
        email: newResume.email,
        phoneNumber: newResume.phoneNumber.split(','),
        address: newResume.address,
        birthDay: newResume.birthDay,
        gender: newResume.gender,
        languages: newResume.languages,
        nationality: newResume.nationality,
        maritalStatus: newResume.maritalStatus,
        drivingLicense: newResume.drivingLicense,
        resumeLanguage: newResume.resumeLanguage,

        hobbies: newResume.hobbies,
        skills: [
            {
                header:
                    newResume.resumeLanguage === 'en'
                        ? 'Skills'
                        : 'Habilidades e competências',
                elements: newResume.skills,
            },
        ] as ISkill[],
        sections: [
            {
                header:
                    newResume.resumeLanguage === 'en'
                        ? 'EDUCATION'
                        : 'PERFIL ACADÊMICO',
                elements: newResume.educations,
            },
            {
                header:
                    newResume.resumeLanguage === 'en'
                        ? 'EXPERIENCE'
                        : 'EXPERIÊNCIA PROFISSIONAL',
                elements: newResume.experiences,
            },
            {
                header:
                    newResume.resumeLanguage === 'en' ? 'COURSES' : 'CURSOS',
                elements: newResume.courses,
            },
            {
                header:
                    newResume.resumeLanguage === 'en'
                        ? 'REFERENCES'
                        : 'REFERÊNCIAS',
                elements: newResume.references,
            },
        ] as ISection[],
    } as IResume;

    return resumeData;
}

export const getFormatedDate = (date: Date | string): string => {
    let formatedDate;
    if (date instanceof String) {
        formatedDate = new Date(date).toLocaleDateString('es-CL', {});
    } else if (date instanceof Date) {
        formatedDate = date.toLocaleDateString('es-CL');
    }

    return formatedDate || 'Wrong date format';
};

export const getFormatedTime = (date: Date | string): string => {
    let formatedTime;
    if (date instanceof String) {
        formatedTime = new Date(date).toLocaleTimeString('it');
    } else if (date instanceof Date) {
        formatedTime = date.toLocaleTimeString('it');
    }

    return formatedTime || 'Wrong date format';
};

export const getFormatedDateTime = (date: Date | string): string => {
    const formatedDateTime =
        getFormatedDate(date) + ' ' + getFormatedTime(date);

    return formatedDateTime || 'Wrong date format';
};
